import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagesService } from '@services/messages/messages.service';
import { ProjectsService } from '@services/projects/projects.service';
import { StorageService } from '@services/storage/storage.service';
import { Observable, Subscription } from 'rxjs';
import { last, map, take } from 'rxjs/operators';
import { MessagesComponent } from './messages/messages.component';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'dash',
  templateUrl: 'dash.component.html',
  styleUrls: ['dash.component.css'],
})
export class DashComponent implements OnInit, OnDestroy {
  userId;
  getUserIdSub: Subscription
  newProject: boolean = false;
  newProjectName: string = '';
  displayedColumns: Array<string> = ["projectName", "projectId", "actions-col"];
  allProjects$: Observable<any>;
  selectedProjectId: string = "";
  selectedProjectName: string = "";

  constructor(
    private projectsService: ProjectsService,
    private storageService: StorageService,
    private messagesService: MessagesService,
    public auth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.getUserIdSub = this.auth.authState.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.allProjects$ = this.setProjectsList();
      }
    })
  }

  setProjectsList() {
    return this.allProjects$ = this.projectsService.getProjects(this.userId).pipe(map(projects => {
      let projectsTab = [];
      for (let project of projects) {
        projectsTab.push({ projectId: project.projectDocumentId, projectName: project['projectData']['projectName'] });

        //affichage par ordre alphabétique des noms de projets :
        projectsTab.sort(function (a, b) {
          if (a.projectName < b.projectName) { return -1; }
          if (a.projectName > b.projectName) { return 1; }
          return 0;
        })
      }
      return projectsTab
    }));
  }

  onClickNewProject() {
    this.newProject = true;
  }

  onClickAddProject(projectName) {
    this.projectsService.addProject(projectName, this.userId).then(docRef => {
      let projectDocId = docRef.id;

      //définir les messages de base pour le projet (texte fin d'expérimentation, bouton next, etc.):
      this.messagesService.addMessages({
        experimentationEnd: "Experimentation end",
        alreadyCompleted: "Test already completed",
        noStimuli: "No stimuli available",
        mediaSharing: "Share my webcam and my screen",
        mediaSharingEnd: "Stop participation",
        nextButton: "Next",
        projectId: projectDocId
      })
    });

    this.newProjectName = "";
  }

  onClickView(projectId, projectName) {
    this.selectedProjectId = projectId;
    this.selectedProjectName = projectName;
    // this.storageService.setRepository(projectId);   //reset la liste des projets (sinon lorsqu'on retourne à la liste après avoir edité un projet, ils s'affichent en double dans le tableau)
  }

  onClickChangeProject() {
    this.selectedProjectId = "";
    this.allProjects$ = this.setProjectsList();
  }

  logout() {
    this.auth.signOut();
    this.selectedProjectId = '';
  }

  ngOnDestroy() {
    if (this.getUserIdSub) this.getUserIdSub.unsubscribe();
  }
}

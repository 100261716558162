import { Component, Input, OnInit } from '@angular/core';
import { FeedbackService } from '@services/feedback/feedback.service';
import { InputsService } from '@services/inputs/inputs.service';
import { InputSequencerService } from '@services/inputSequencer/input-sequencer.service';

@Component({
  selector: 'yes-or-no-question',
  templateUrl: './yes-or-no-question.component.html',
  styleUrls: ['./yes-or-no-question.component.css']
})
export class YesOrNoQuestionComponent implements OnInit {

  @Input() inputId;
  @Input() projectId;
  @Input() inputIndex;
  @Input() inputFormData;

  constructor(
    private inputSequencerService: InputSequencerService,
    private inputsService: InputsService,
    private feedbackService: FeedbackService
  ) { }

  ngOnInit(): void {
    if (this.inputId && this.projectId) {
      this.inputsService.getInputInitialValues(this.inputId, this.projectId).subscribe(inputVal => {
        this.inputFormData = inputVal[0]['inputData'];
      });
    }
  }

  onFinish() {
    if (this.inputId && this.projectId) {
      let parameterName = 'input' + this.inputIndex;
      this.inputSequencerService.setStatus(parameterName, true);
      this.feedbackService.setFeedbackValues(
        {
          [this.inputFormData['inputCode']]: this.inputFormData["value"]
        }
      );
    }
  }
}

<!-- START VALUE -->
<!-- <mat-form-field appearance="fill">
  <mat-label>Start Value</mat-label>
  <mat-select [(ngModel)]="inputFormData['value']" name="value" required>
    <mat-option *ngFor="let value of valuesList" [value]="value">
      {{ value }}
    </mat-option>
  </mat-select>
  </mat-form-field><br> -->
  <mat-form-field appearance="fill">
  <mat-label>Number of options</mat-label>
    <input matInput type="number" [(ngModel)]="numberOfOptions" name="numberOfOptions" (change)="onNumberOfOptionsChange()">
  </mat-form-field><br>
  <div fxLayout="column wrap" fxLayoutAlign="space-around">
    <mat-form-field *ngFor="let x of [].constructor(numberOfOptions);index as i;"appearance="fill">
      <mat-label>Option {{i+1}} </mat-label>
      <input matInput [(ngModel)]="options[i]" [name]="i" (change)="onOptionChange(i)">

    </mat-form-field>
  <div>

<button mat-raised-button color="primary" (click)="onSave()">Save</button>

<mat-card>
  <mat-form-field appearance="fill" ngDefaultControl>
    <mat-label>Participant</mat-label>
    <mat-select [(ngModel)]="selectedParticipant">
      <mat-option *ngFor="let participant of participants" [value]="participant">
        {{participant}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" class="btn export-participant" (click)="onExportParticipant()">
    Export participant
  </button>
  <!-- <button mat-raised-button color="primary" class="btn"  (click)="onExport()">
  Export data
</button> -->
  <!-- <button class="btn" mat-raised-button color="warn"  (click)="onClear()">
  Clear All Data
</button> -->
</mat-card>

<mat-card>
  <button mat-raised-button color="primary" class="btn" (click)="onExportProject()">
    Export project
  </button>
</mat-card>
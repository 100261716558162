<mat-form-field appearance="legacy" ngDefaultControl class="select-participant">
  <mat-label>Select participant</mat-label>
  <mat-select [(ngModel)]="selectedParticipant" (selectionChange)="onParticipantSelect()">
    <mat-option *ngFor="let participant of participantNames" [value]="participant">
      {{ participant }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="dash">
  <div>
    <ng-container *ngIf="selectedParticipant">
      <div class="spinner-container" *ngIf="!(cameraDurationAvailable && screenDurationAvailable)">
        <mat-progress-spinner 
          mode="determinate"
          value="{{ (currentScreenTime * 100 / estimateVideoDuration).toFixed(0) }}">
        </mat-progress-spinner>
        <div class="processing-percentage">{{ (currentScreenTime * 100 / estimateVideoDuration).toFixed(0) }}% </div>
        <div>Processing videos...</div>
        <!-- <div>Remaining time estimation: {{ estimateProcessingVideoTime }}</div> -->
      </div>
      <div class="videos">
        <mat-card>
          <div>
            <video #videoScreen controls disablePictureInPicture [src]="participantRecordsSrc['screen']" (click)="onPlayerVideoClick($event)"></video>
          </div>
          <div class="control-section">
            <div>Current time (s) : {{ currentScreenTime }} / {{ videoScreen.duration }}</div>
            <!-- <div>{{ formatedCurrentScreenTime }} / {{ formatedScreenDuration }}</div> -->
            <div class="control-btns">
              <button (click)="onPlay()" matTooltip="Play">
                <span class="material-icons">
                  play_circle_outline
                </span>
              </button>
              <button (click)="onPause()" matTooltip="Pause">
                <span class="material-icons">
                  pause_circle_outline
                </span>
              </button>
              <button (click)="onStop()" matTooltip="Stop">
                <span class="material-icons">
                  stop
                </span>
              </button>
              <button (click)="onRewind()" matTooltip="Rewind">
                <span class="material-icons">
                  fast_rewind
                </span>
              </button>

              <!-- Fast forward : -->
              <button (click)="onPreviousFrame(1)">
                -1ms
              </button>
              <button (click)="onNextFrame(1)">
                +1ms
              </button>
              <button (click)="onPreviousFrame(10)">
                -10ms
              </button>
              <button (click)="onNextFrame(10)">
                +10ms
              </button>
              <button (click)="onPreviousFrame(100)">
                -100ms
              </button>
              <button (click)="onNextFrame(100)">
                +100ms
              </button>

              <!-- Plqyback speed : -->
              <!-- <button [matMenuTriggerFor]="menu" matTooltip="Playback speed: {{ currentSpeed }}">
                <mat-icon>speed</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="matMenu">
                <button mat-menu-item  (click)="setCurrentSpeed('0.25')" [ngClass]="{'currentSpeed' : currentSpeed === '0.25'}">
                  <span>0.25</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('0.5')" [ngClass]="{'currentSpeed' : currentSpeed === '0.5'}">
                  <span>0.5</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('0.75')" [ngClass]="{'currentSpeed' : currentSpeed === '0.75'}">
                  <span>0.75</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('1')" [ngClass]="{'currentSpeed' : currentSpeed == '1'}">
                  <span>1</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('1.25')" [ngClass]="{'currentSpeed' : currentSpeed == '1.25'}">
                  <span>1.25</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('1.5')" [ngClass]="{'currentSpeed' : currentSpeed == '1.5'}">
                  <span>1.5</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('1.75')" [ngClass]="{'currentSpeed' : currentSpeed == '1.75'}">
                  <span>1.75</span>
                </button>
                <button mat-menu-item  (click)="setCurrentSpeed('2')" [ngClass]="{'currentSpeed' : currentSpeed == '2'}">
                  <span>2</span>
                </button>
              </mat-menu> -->
            </div>
          </div>
        </mat-card>
        <mat-card>
          <div>
            <video #videoCamera class="video-camera" controls disablePictureInPicture [src]="participantRecordsSrc['camera']" (click)="onPlayerVideoClick($event)"></video>
          </div>
        </mat-card>
      </div>
      <!-- <div class="download">
        <button mat-raised-button>
          <a [href]="participantRecordsSrc['camera']" target="_blank" download>
            Download webcam video
          </a>
        </button>
        <button mat-raised-button>
          <a [href]="participantRecordsSrc['screen']" target="_blank" download>
            Download screen video
          </a>
        </button>
      </div> -->
    </ng-container>
  </div>

  <div class="marker-table">
    <ng-container *ngIf="cameraDurationAvailable && screenDurationAvailable">
      <div class="before-table">
        <button mat-button (click)="onAddMarker()">
          <mat-icon>
            add_box
          </mat-icon> 
          Add marker
        </button>
      </div>
      <table mat-table [dataSource]="markers$" class="mat-elevation-z8">
        <!-- Project Name Column -->
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef> Timestamp </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button (click)="onTimestampClick(element.timestamp)">
              {{element.timestamp}}
            </button>
          </td>
        </ng-container>

        <!-- Project Id Column -->
        <ng-container matColumnDef="markerName">
          <th mat-header-cell *matHeaderCellDef> Marker name </th>
          <td mat-cell *matCellDef="let element">
            <input type="text" matInput [(ngModel)]="element.markerName" #markerInput (ngModelChange)="onMarkerNameChange()">
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions-col">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <button mat-button (click)="onDeleteMarker(i)">
              <mat-icon>
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="after-table">
        <!-- <div>
          <mat-form-field appearance="legacy">
            <mat-label>Enter latency (ms)</mat-label>
            <input #latencyInput matInput>
          </mat-form-field>
          <button mat-stroked-button (click)="applyLatency(latencyInput.value)">Apply</button>
        </div>
        <ng-container *ngIf="latency == 0">
          <span><em>No latency applied.</em></span>
        </ng-container>
        <ng-container *ngIf="latency != 0">
          <span><em>A latency of {{latency}} seconds is applied on webcam video.</em></span>
        </ng-container> -->
        <button mat-button mat-raised-button class="export-btn" (click)="onExport()">Export markers</button>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="auth.user | async as user; else showLogin">
  <div class="wrapper">
    <div class="app-header">
      <img class="logo" src="assets/logo.png" alt="logo cobalt capture" (click)="onClickChangeProject()">
      <button mat-stroked-button (click)="logout()" class="logout-btn">
        <mat-icon matTooltip="Logout">logout</mat-icon>
      </button>
    </div>
  
    <div class="app-content">
      <ng-container *ngIf="selectedProjectId == ''">
        <!-- AJOUT DE PROJET : -->
        <div class="navigation">
          <mat-accordion>
            <mat-expansion-panel hideToggle [expanded]="newProjectName != '' ">
              <mat-expansion-panel-header>
                <mat-panel-title class="new-project-btn">
                  <mat-icon> library_add </mat-icon>
                  <span>New project</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field>
                <mat-label>Project Name</mat-label>
                <input appearance="fill" matInput type="text" [(ngModel)]="newProjectName">
              </mat-form-field>
              <button mat-stroked-button *ngIf="newProjectName" (click)="onClickAddProject(newProjectName)">Create</button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
    
        <!-- TABLEAU : -->
        <table mat-table [dataSource]="allProjects$ | async " class="mat-elevation-z8">
          <!-- Project Name Column -->
          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef> Project Name </th>
            <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
          </ng-container>
    
          <!-- Project Id Column -->
          <ng-container matColumnDef="projectId">
            <th mat-header-cell *matHeaderCellDef> Project Id </th>
            <td mat-cell *matCellDef="let element"> {{element.projectId}} </td>
          </ng-container>
    
          <!-- Actions Column -->
          <ng-container matColumnDef="actions-col">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element"> <button mat-stroked-button matTooltip="View project"
                (click)="onClickView(element.projectId, element.projectName)">
                <mat-icon>arrow_forward_ios</mat-icon>
              </button> </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-container>
      <!-- </ng-container> -->
    
      <!-- AFFICHAGE DU DASH DU PROJET CHOISI : -->
      <!-- <ng-container *ngIf="auth.user | async as user; else showLogin"> -->
        <div class="project-dash">
          <ng-container *ngIf="selectedProjectId != ''">
        
            <header fxLayoutAlign="space-between">
              <div>
                <h1>| {{ selectedProjectName }} </h1>
                <!-- <h1>- {{ selectedProjectName }} -</h1> -->
                <!-- <h5>Project id: {{selectedProjectId }}</h5> -->
              </div>
              <button mat-stroked-button (click)="onClickChangeProject()" class="changeProjectBtn">
                <mat-icon matTooltip="Back to projects list">arrow_back</mat-icon>
              </button>
              <!-- <span class="example-spacer"></span> -->
            </header>
        
            <mat-tab-group dynamicHeight>
        
              <mat-tab label="Participants">
                <ng-template matTabContent>
                  <sequencies [projectId]="selectedProjectId" [userId]="userId"></sequencies>
                </ng-template>
              </mat-tab>
        
              <!--
              <mat-tab label="Inputs">
                <ng-template matTabContent>
                  <inputs [projectId]="selectedProjectId"></inputs>
                </ng-template>
              </mat-tab>
        
              <mat-tab label="Export">
                <ng-template matTabContent>
                  <export [projectId]="selectedProjectId"></export>
                </ng-template>
              </mat-tab>
        
              <mat-tab label="Messages">
                <ng-template matTabContent>
                  <messages [projectId]="selectedProjectId"></messages>
                </ng-template>
              </mat-tab>
            -->
        
              <mat-tab label="Annotations">
                <ng-template matTabContent>
                  <record-markers [projectId]="selectedProjectId"></record-markers>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
        </div>
    </div>
  
    <div class="app-footer">
      <a href="https://tech3lab.hec.ca/" target="_blank">
        <img class="logo" src="assets/footer_logo.png" alt="logo tech3lab hec">
      </a>
    </div>
  </div>
</ng-container>

<ng-template #showLogin>
  <ngx-auth-firebaseui-login [providers]="[]" [registrationEnabled]="false" [resetPasswordEnabled]="false">
  </ngx-auth-firebaseui-login>
</ng-template>
import { Injectable } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InputSequencerService {

  inputValues = [];
  inputSequency;
  statusCollector: object = {};
  statusCollector$: Subject<object> = new Subject();
  displayDirectives: object = {};
  displayDirectives$: Subject<object> = new Subject(); //pour passer l'info au component
  statusCollectorSub: Subscription;

  constructor(
    private inputsService: InputsService
  ) { }

  async setInputSequency(inputSequency) {
    this.inputValues = [];
    this.inputSequency = inputSequency;
    for (let i = 0; i < inputSequency.length; i++) {
      this.displayDirectives['input' + i] = false;
      this.statusCollector['input' + i] = false;

      let inputVal = await this.inputsService.getInput(inputSequency[i]).toPromise();
      // console.log("inputVal",i,":", inputVal);

      this.inputValues[i] = inputVal;

    }
    this.displayDirectives['nextBtnDisplay'] = false;
    this.statusCollector['mediaEnded'] = false;

    if(this.statusCollectorSub) {
      console.log("UNSUBSCRIBE statusCollectorSub")
      this.statusCollectorSub.unsubscribe();
    }

    this.statusCollectorSub = this.statusCollector$.subscribe(_ => {
      this.drawStatus();
    });

    this.statusCollector$.next(this.statusCollector);
    this.displayDirectives$.next(this.displayDirectives);
    // console.log("this.inputValues", this.inputValues);
  }


  drawStatus() {
    if (this.statusCollector['mediaEnded']) {
      this.displayConditionFreeInputs();
      this.checkAndDisplayInputsWithPreviousAnswerCondition();
      this.checkAndDisplayInputsHidingPreviousInputs();
      this.displayNextButton();
    }
    // console.log("this.statusCollector:", this.statusCollector);
    // console.log("this.displayDirectives:", this.displayDirectives);
    this.displayDirectives$.next(this.displayDirectives);
  }

  displayConditionFreeInputs() {
    for (let inputIndex = 0; inputIndex < this.inputSequency.length; inputIndex++) {
      if (!this.inputValues[inputIndex]['hidePreviousInputs'] && !this.inputValues[inputIndex]['needPreviousInputsAnswer']) {
        this.displayDirectives['input' + inputIndex] = true;
      } else {
        this.displayDirectives['input' + inputIndex] = false;
      }

      //on ne peut pas afficher un input d'indice i, si l'input d'incide i-1 n'est pas fini, sauf si son 'needPreviousInputsAnswer' est à false:
      /*  if (this.statusCollector['input' + (inputIndex - 1)] == false && this.inputValues[inputIndex] == false) {
         this.displayDirectives['input' + inputIndex] = false;
       } */
      // if (this.statusCollector['input' + (inputIndex - 1)] == false && !this.inputValues[inputIndex]['needPreviousInputsAnswer']) {
      //   this.displayDirectives['input' + inputIndex] = true;
      // } 
      // else {
      //   this.displayDirectives['input' + inputIndex] = false;
      // }
    }
  }

  checkAndDisplayInputsWithPreviousAnswerCondition() {
    for (let inputIndex = 0; inputIndex < this.inputSequency.length; inputIndex++) {
      if (this.inputValues[inputIndex]['needPreviousInputsAnswer']) {
        if (inputIndex != 0) {
          let counter = 0;

          for (let j = 0; j < inputIndex; j++) {
            // console.log("this.statusCollector['input'" +j + "]:", this.statusCollector['input' + j])
            if (this.statusCollector['input' + j] == true) {
              counter++;
            }

            if (counter == inputIndex) {
              this.displayDirectives['input' + inputIndex] = true;
            } else {
              this.displayDirectives['input' + inputIndex] = false;
            }
          }
        } else {
          this.displayDirectives['input' + inputIndex] = true;
        }
      }
    }
  }

  checkAndDisplayInputsHidingPreviousInputs() {
    for (let inputIndex = 0; inputIndex < this.inputSequency.length; inputIndex++) {
      if (this.inputValues[inputIndex]['hidePreviousInputs']) {
        let counter = 0;
        for (let j = 0; j < inputIndex; j++) {
          if (this.statusCollector['input' + j] == true) {
            counter++;
          }
        }
        if (counter == inputIndex) {
          for (let k = 0; k < counter; k++) {
            this.displayDirectives['input' + k] = false;
          }
          this.displayDirectives['input' + inputIndex] = true;
        }
      }
    }
  }

  displayNextButton() {
    let answeredInputsCount = 0;
    for (let inputIndex = 0; inputIndex < this.inputSequency.length; inputIndex++) {
      if (this.statusCollector['input' + inputIndex] == true) answeredInputsCount++;
      // if (this.statusCollector['input' + (this.inputSequency.length - 1)] == true) {
      //   this.displayDirectives['nextBtnDisplay'] = true;
      // }
    }

    if (answeredInputsCount == this.inputSequency.length) {
      this.displayDirectives['nextBtnDisplay'] = true;
    }

    if (this.inputSequency.length == 0) {
      this.displayDirectives['nextBtnDisplay'] = true;
    }
  }

  setStatus(parameterName, status) {
    this.statusCollector[parameterName] = status;
    this.statusCollector$.next(this.statusCollector);
  }


  getInputsDisplayDirectives() {
    return this.displayDirectives$;
  }


  resetInputDisplayStatus() {
    this.setStatus("mediaEnded", false);
    for (let i = 0; i < this.inputSequency.length; i++) {
      let parameterName = 'input' + i;
      this.setStatus(parameterName, false);
    }

    for (let inputDisplayDirective in this.displayDirectives) {
      this.displayDirectives[inputDisplayDirective] = false;
    }
  }
}

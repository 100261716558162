  <!-- MINIMUM VALUE -->
  <mat-form-field appearance="fill">
    <mat-label>Min Value</mat-label>
    <input matInput [(ngModel)]="inputFormData['minValue']" name="minValue">
  </mat-form-field><br>
  
  <!-- MAXIMUM VALUE -->
  <mat-form-field appearance="fill">
    <mat-label>Max Value</mat-label>
    <input matInput [(ngModel)]="inputFormData['maxValue']" name="maxValue">
  </mat-form-field><br>
  
  <!-- START VALUE -->
  <mat-form-field appearance="fill">
    <mat-label>Start Value</mat-label>
    <input matInput [(ngModel)]="inputFormData['value']" name="value">
  </mat-form-field><br>
  
  <!-- SI GRADUATION, CHOISIR LE STEP : -->
  <mat-form-field appearance="fill">
    <mat-label>Graduation step</mat-label>
    <input matInput [(ngModel)]="inputFormData['sliderStep']" name="sliderStep">
  </mat-form-field><br>
  
  <!-- MONTRER LA VALEUR SÉLECTIONNÉE DANS UNE BULLE : -->
  <mat-card appearance="fill">
    <mat-checkbox [(ngModel)]="inputFormData['showValue']" name="showValue" color='primary'>Show selected
      value
    </mat-checkbox>
  </mat-card><br>
  
  <!-- IMG GAUCHE -->
  <mat-card appearance="fill">
    <label>Left image</label><br>
    <input type=file #leftImg (change)="onChangeImgLeft(leftImg.files)">
  </mat-card><br>
  
  <!-- IMG DROITE -->
  <mat-card appearance="fill">
    <label>Right image</label><br>
    <input type=file #rightImg (change)="onChangeImgRight(rightImg.files)">
  </mat-card><br>
  
  <button mat-raised-button color="primary" (click)="onSave()">Save</button>

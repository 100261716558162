import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Environment } from '@environments/environment';
import { timer } from 'rxjs';
import { take, withLatestFrom, map, filter, first } from 'rxjs/operators';
import { BehaviorSubject, interval, Subject, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { webSocket } from 'rxjs/webSocket';
import { LatencyService } from '@services/latency/latency.service';
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})

export class FeedbackService {
  private feedbackCollection: AngularFirestoreCollection<any>;
  feedbacksObs: Observable<any[]>;
  feedbacksIds: any[];
  feedbacks: any[];
  currentFeedbackDocId = '';

  constructor(
    private afs: AngularFirestore,
    private _http: HttpClient,
    private latencyService: LatencyService,
  ) {
    this.feedbackCollection = this.afs.collection<any>('feedbacks');
    this.feedbacksObs = this.feedbackCollection.valueChanges();
    this.feedbacksObs.subscribe((data) => {
      // console.log("data",data)
      this.feedbacks = data;
    })
    this.feedbackCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const id = a.payload.doc.id;
        return id;
      }))
    ).subscribe((ids) => {
      this.feedbacksIds = ids;
      // console.log("data_ids",ids)
    })
    const source = interval(2000);
    // const subscribe = source.subscribe(val => {
    //   this._http.get('https://uxstimuli.web.app', { observe: 'response' })
    //   .pipe(first())
    //   .subscribe();
    // });
  }

  getObs() {
    return this.feedbacksObs;
  }

  addFeedback(feedback: any) {
    return this.feedbackCollection.add(feedback)
  }

  updateFeedback(documentId, feedback) {
    return this.afs.collection('feedbacks').doc(documentId).update(feedback);
  }


  getFeedback(userId, videoId, projectId) {
    return this.afs.collection('feedbacks', ref => ref.where('id', "==", userId).where('video_id', "==", videoId).where('project_id', "==", projectId)).snapshotChanges()
      .pipe(first()
      ).toPromise();
  }

  getFeedbacksIdsByUserAndProject(userId, projectId) {
    return this.afs.collection('feedbacks', ref => ref.where('id', '==', userId.toString()).where('project_id', '==', projectId.toString())).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const feedbacksDocumentId = a.payload.doc.id;
          return feedbacksDocumentId;
        })),
        first()
      ).toPromise();
  }

  async isExistingFeedback(userId, projectId) {
    let existingFeedback = await this.getFeedbacksIdsByUserAndProject(userId, projectId);
    if(existingFeedback.length == 0) return true;
    else return false;
  }

  clearAllFeedbacks() {
    for (let fid of this.feedbacksIds) {
      let doc = this.afs.doc<any>('feedbacks/' + fid);
      doc.delete();
    }
  }

  clearFeedback(feedbackDocId) {
    let doc = this.afs.doc<any>('feedbacks/' + feedbackDocId);
    doc.delete();
  }

  get() {
    return this.feedbacks;
  }

  async setFeedbackValues(feedbackValues) {

      //nouveau feedback :
      if (this.currentFeedbackDocId == '') {
        let existingFeedback = await this.getFeedback(feedbackValues['id'], feedbackValues['video_id'], feedbackValues['project_id']);

        if (existingFeedback.length == 0) {
          this.addFeedback(feedbackValues)
            .then(docRef => {
              this.currentFeedbackDocId = docRef.id;
            });
        } else {
          this.currentFeedbackDocId = existingFeedback[0].payload.doc.id;
        }
      }
      //feedback existant
      else {
        return this.updateFeedback(this.currentFeedbackDocId,feedbackValues)
      }
  }

  setFeedbackVideoValues(feedbackValues) {
    return this.updateFeedback(this.currentFeedbackDocId, feedbackValues)
  }

}

<!-- <ng-container *ngIf="inputFormData"> -->
<ng-container *ngIf="inputFormData">
  <div class="multipleChoice" >
     <span>{{ inputFormData['inputText'] }}</span>`
  </div>
  <div class="multipleChoice" >
    <mat-radio-group (change)="onFinish()"
      [(ngModel)]="inputFormData['value']">
      <div fxLayout="column wrap" fxLayoutAlign="space-around">
        <mat-radio-button *ngFor="let option of inputFormData['options']" [value]="option">
          {{ option }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <!-- <mat-radio-group (change)="onFinish()"
      [(ngModel)]="inputFormData['value']">
      <mat-radio-button *ngFor="let iValue of inputFormData['inputLabels'].length" [value]="iValue">
        {{ iValue }}
      </mat-radio-button>
    </mat-radio-group> -->
    <!-- <input id="yes" name="yesOrNoQuestion" type="radio" value="yes" [(ngModel)]="inputFormData['value']">
    <label for="yes"> {{ inputFormData['inputYesText'] }} </label>
    <input id="no" name="yesOrNoQuestion" type="radio" value="no" [(ngModel)]="inputFormData['value']">
    <label for="no"> {{ inputFormData['inputNoText'] }} </label> -->
  </div>
</ng-container>

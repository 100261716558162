import { Component, Input, OnInit, SimpleChanges,OnChanges } from '@angular/core';
import { firestore } from 'firebase/app';
import { LatencyService } from '@services/latency/latency.service';
import { FeedbackService } from '@services/feedback/feedback.service';
import { InputSequencerService } from '@services/inputSequencer/input-sequencer.service';


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit,OnChanges{

  @Input() url;
  @Input() mediaText:string;
  mediaStarted: boolean = false;;
  mediaEnded:boolean = false;


  constructor(
    private feedbackService: FeedbackService,
    private latencyService: LatencyService,
    private inputSequencerService: InputSequencerService
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes){
    if(changes.url){
      if(changes.url.currentValue!=changes.url.previousValue){
        this.mediaEnded=false;
        this.mediaStarted=false;
      }
    }
    if(changes.mediaText){
      if(changes.mediaText.currentValue!=changes.mediaText.previousValue)
        this.mediaEnded=false;
    }
  }
  onMediaStart() {
    this.mediaStarted=true;
    let skew = this.latencyService.getSkew();
    var startLocalTimestamp = firestore.Timestamp.fromDate(new Date());

    this.feedbackService.setFeedbackVideoValues(
      {
        skew: skew,
        startedAt_browser: startLocalTimestamp,
        startedAt: firestore.FieldValue.serverTimestamp(),
      })
      .then(_ => {
        let latency = this.latencyService.get();
        /*   ajout de la latence dans la bdd : */
        this.feedbackService.setFeedbackVideoValues({ startedAt_latency: latency / 2 })
      });
  }

  onMediaEnd() {
    this.mediaEnded=true;
    let skew = this.latencyService.getSkew();
    var stopLocalTimestamp = firestore.Timestamp.fromDate(new Date());

    this.inputSequencerService.setStatus('mediaEnded', true);

    this.feedbackService.setFeedbackVideoValues(
      {
        skew: skew,
        stoppedAt_browser: stopLocalTimestamp,
        stoppedAt: firestore.FieldValue.serverTimestamp()
      })
      .then(_ => {
        let latency = this.latencyService.get();
        /* ajout de la latence dans la bdd : */
        this.feedbackService.setFeedbackVideoValues({ stoppedAt_latency: latency / 2 })
      });
  }
}

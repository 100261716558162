<mat-card>
  <div class="logo">
    <img src="/assets/logo.png" alt="">
    <img src="/assets/hec.jpg" alt="">
  </div>
  <ng-container *ngIf="!cameraAvailable">
    <p>No camera available.</p>
  </ng-container>
  <ng-container *ngIf="cameraAvailable">
    <ng-container *ngIf="!recordingStarted">
      <p>By clicking on the following button you agree to participate in this experiment and share your webcam and screen.
      </p>
      <p>Your webcam and screen will be recorded.</p>
      <p>Please do not close this tab before the end of the experiment.</p>
      <p>After the end of the experiment, please click on the button "{{ msgMediaSharingEnd }}".</p>
      <p>After that, please wait for a few minutes until all the data is uploaded.</p>
      <button mat-raised-button (click)="onAcceptMediaSharing()">{{ msgMediaSharing}}</button>
    </ng-container>
    <ng-container *ngIf="recordingStarted  && !recordingEnded">
      <p>Your webcam and screen are being recorded.<br>
        <strong>Please do not close this tab before the end of the experiment.</strong>
      </p>
      <p>After the end of the experiment, please click on the button "{{ msgMediaSharingEnd }}".</p>
      <p>After that, please wait for a few minutes until all the data is uploaded.</p>
      <button mat-raised-button color="warn" (click)="onStopRecording()">{{ msgMediaSharingEnd }}</button>
    </ng-container>
    <ng-container *ngIf="recordingEnded && (!isScreenFileUploaded || !isWebcamFileUploaded)">
      <!-- <mat-spinner></mat-spinner> -->
      <p>Uploading data... <strong>Please do not close this tab.</strong></p>
      <ng-container *ngIf="screenFileUploadedPercentage > 0">
        <span>Screen data:</span>
        <div class="upload_bar">
          <mat-progress-bar mode="determinate" [value]="screenFileUploadedPercentage"></mat-progress-bar>
          <div class="upload_percentage">{{ screenFileUploadedPercentage.toFixed(0) }}%</div>
        </div>
      </ng-container>
      <br>
      <ng-container *ngIf="cameraFileUploadedPercentage > 0">
        <span>Camera data: </span>
        <div class="upload_bar">
          <mat-progress-bar mode="determinate" [value]="cameraFileUploadedPercentage"></mat-progress-bar>
          <div class="upload_percentage">{{ cameraFileUploadedPercentage.toFixed(0) }}%</div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isScreenFileUploaded && isWebcamFileUploaded">
      <p>Data saved. Thank you for your participation. You can now close this tab.</p>
    </ng-container>
  </ng-container>
</mat-card>
import { Component, Input, OnInit } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.css']
})
export class InputFormComponent implements OnInit {
  @Input() projectId: string;
  @Input() inputId: string;

  inputTypesTab: Array<string> = ["Slider", "Yes or no question", "Multiple choice question"];
  inputFormData: object = {};
  inputCodeErr;
  inputErrors = [];

  constructor(
    private inputsService: InputsService
  ) { }

  async ngOnInit() {
    if (this.inputId != '') {
      await this.inputsService.getInput(this.inputId).pipe(take(1)).toPromise().then(inputValues => {
        this.inputFormData = {
          projectId: this.projectId,
          inputType: inputValues['inputType'],
          inputId: this.inputId,
          inputName: inputValues['inputName'],
          inputCode: inputValues['inputCode'],
          inputText: inputValues['inputText'] != undefined ? inputValues['inputText'] : "",
          hidePreviousInputs: inputValues['hidePreviousInputs'] != undefined ? inputValues['hidePreviousInputs'] : "",
          needPreviousInputsAnswer: inputValues['needPreviousInputsAnswer'] != undefined ? inputValues['needPreviousInputsAnswer'] : "",
          value: inputValues['value'] != undefined ? inputValues['value'] : "",
        }
        if(inputValues['inputType'] === 'Yes or no question'){
          this.inputFormData['inputNoText'] = inputValues['inputNoText'] != undefined ? inputValues['inputNoText'] : "";
          this.inputFormData['inputYesText'] = inputValues['inputYesText'] != undefined ? inputValues['inputYesText'] : "";
        }
        if(inputValues['inputType'] === 'Multiple choice question'){
          this.inputFormData['options'] = inputValues['options'] != undefined ? inputValues['options'] : ["Option 1","Option 2"];
          this.inputFormData['options'] = inputValues['numberOfOptions'] != undefined ? inputValues['options'] : 2;
        }
        if (inputValues['inputType'] === 'Slider') {
          this.inputFormData['maxValue'] = inputValues['maxValue'] != undefined ? inputValues['maxValue'] : "";
          this.inputFormData['minValue'] = inputValues['minValue'] != undefined ? inputValues['minValue'] : "";
          this.inputFormData['sliderStep'] = inputValues['sliderStep'] != undefined ? inputValues['sliderStep'] : "";
          this.inputFormData['showValue'] = inputValues['showValue'] != undefined ? inputValues['showValue'] : "";
          this.inputFormData['imgLeftDBPath'] = inputValues['imgLeftDBPath'] != undefined ? inputValues['imgLeftDBPath'] : "";
          this.inputFormData['imgRightDBPath'] = inputValues['imgRightDBPath'] != undefined ? inputValues['imgRightDBPath'] : "";
        }
      });
    } else {
      this.inputFormData = { projectId: this.projectId, showValue : false, hidePreviousInputs : false, needPreviousInputsAnswer: false }
    }
  }

  changeInputCodeErr(event) {
    this.inputCodeErr = event;
  }

  changeInputErrors(event){
    this.inputErrors = event;
  }

  /**
  * Actions à faire au changement de valeur d'un des inputs du formulaire
  * @param event
  */
  onFormChange(event) {

    /* multiple choice question : Création d'un tableau de la longueur du nb de choix entré pour itérer dessus dans le rendu : */
    // if (event.target.name === 'multipleChoiceNumber') {
    //   this.multipleChoiceNumberArray = []; //reset du tableau à chaque fois
    //   for (let i = 1; i <= this.multipleChoiceNumber; i++) {
    //     this.multipleChoiceNumberArray.push(i);
    //   }
    // }
  }
}

import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '@services/feedback/feedback.service';
import { firestore } from 'firebase/app';
import { ActivatedRoute } from '@angular/router';
import { SequenciesService } from '@services/sequencies/sequencies.service';
import { StorageService } from '@services/storage/storage.service';
import { LatencyService } from '@services/latency/latency.service';
import { InputSequencerService } from '@services/inputSequencer/input-sequencer.service';
import { MessagesService } from '@services/messages/messages.service';

@Component({
  selector: 'fetch',
  templateUrl: 'fetch.component.html',
  styleUrls: ['fetch.component.css'],
})
export class FetchPageComponent implements OnInit {
  mediaUrls: Array<string> = [];
  mediaTexts: Array<string> = [];
  mediaIndex: number = 0;
  id: string;
  projectId: string;
  mediaType: string;
  currentUrl: string = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
  isLastMedia = false;
  isMediaSequencySet = true;
  firstAttempt: boolean = true;
  inputsSequency: Array<Array<string>> = [];

  // inputCodesValues: Array<any> = [];
  displayStatus = {}; //tableau de booléens (indiquant si l'affichage des inputs doit se faire. Par index d'inputs.

  msgAlreadyCompleted;
  msgExperimentationEnd;
  msgNoStimuli;
  msgNextButton;

  constructor(
    private feedbackService: FeedbackService,
    private route: ActivatedRoute,
    private sequenciesService: SequenciesService,
    private storageService: StorageService,
    private latencyService: LatencyService,
    private inputSequencerService: InputSequencerService,
    private messagesService: MessagesService
  ) {
    window.onbeforeunload = function (e) {
      var e = e || window.event;
      if (e) {
        e.returnValue = 'La fermeture de la page mettra fin au test...';
      }
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.id = params['id'];
      this.projectId = params['project'];

      this.firstAttempt = await this.feedbackService.isExistingFeedback(this.id, this.projectId);

      /* définir le dossier storage où aller chercher les vidéos : */
      // this.storageService.setRepository(this.projectId)

      this.sequenciesService.getSequency(this.id, this.projectId).subscribe(sequency => {

        //si la séquence est définie dans la BDD et qu'elle n'est pas vide :
        if (sequency[0] != undefined && sequency[0].mediaSequency != "") {

          //récupérer la séquence d'input :
          for (let indexMedia in sequency[0]['inputsSequency']) {
            this.inputsSequency[indexMedia] = sequency[0]['inputsSequency'][indexMedia];
          }

          //récupérer les séquences de média :
          this.mediaUrls = sequency[0]['mediaSequency'];
          this.mediaTexts = sequency[0]['mediaText'];
          if(!this.mediaTexts)
            this.mediaTexts = [];
          this.loadMedia(this.mediaUrls[this.mediaIndex]);
          this.setFeedback();
        } else {
          this.isMediaSequencySet = false;
        }
      });
    });

    //récupérer les directives d'affichages depuis le service :
    this.inputSequencerService.getInputsDisplayDirectives().subscribe(displayStatus => {
      this.displayStatus = displayStatus;
    })

    //récupérer les messages à afficher :
    this.messagesService.getMessages(this.projectId).subscribe(messages => {
      if (messages.length != 0) {
        this.msgAlreadyCompleted = messages[0]['messagesData']['alreadyCompleted'];
        this.msgExperimentationEnd = messages[0]['messagesData']['experimentationEnd'];
        this.msgNoStimuli = messages[0]['messagesData']['noStimuli'];
        this.msgNextButton = messages[0]['messagesData']['nextButton'];
      }
    })
  }

  async setFeedback() {
    //
    await this.feedbackService.setFeedbackValues(
      {
        id: this.id,
        project_id: this.projectId,
        video_id: this.mediaIndex,
        video_name: this.mediaUrls[this.mediaIndex],
      })
  }

  /**
   * Charger un média depuis le répertoire de firestorage "/media" du projet défini dans ngOnInit()
   * @param url : nom du média à charger
   */
  loadMedia(url) {
    //envoi de la séquence d'input au service pour déterminer les directives d'affichage des inputs :
    this.inputSequencerService.setInputSequency(this.inputsSequency[this.mediaIndex]);

    // const ref = this.storageService.getStorageFileRef(this.projectId, "/media/" + url);
    // //récupérer l'url du fichier à charger :
    // let profileUrl = ref.getDownloadURL();
    // profileUrl.subscribe((val) => {
    //   this.currentUrl = val;
    //   //Connaitre le MIME type du fichier correspondant à this.currentUrl :
    //   let profileMetaData = ref.getMetadata();
    //   profileMetaData.subscribe(valeur => {
    //     this.mediaType = valeur['contentType'];
    //   })
    // })
  }

  onNext() {
    this.inputSequencerService.resetInputDisplayStatus();
    window.scrollTo(0, 0);

    let skew = this.latencyService.getSkew();
    var createdLocalTimestamp = firestore.Timestamp.fromDate(new Date());
    this.inputSequencerService.resetInputDisplayStatus();

    this.feedbackService.setFeedbackValues(
      {
        id: this.id,
        project_id: this.projectId,
        skew: skew,
        createdAt_browser: createdLocalTimestamp,
        createdAt: firestore.FieldValue.serverTimestamp(),
        video_name: this.mediaUrls[this.mediaIndex],
        video_id: this.mediaIndex,
      })
      .then(_ => {
        let latency = this.latencyService.get();
        //ajout de la latence dans la bdd :
        this.feedbackService.setFeedbackValues({ createdAt_latency: latency / 2 })
          .then(_ => {
            this.feedbackService.currentFeedbackDocId = '';
            this.mediaIndex = this.mediaIndex + 1;

            if (this.mediaIndex >= this.mediaUrls.length) {
              this.mediaIndex = this.mediaUrls.length - 1;
              this.isLastMedia = true;
            } else {
              this.loadMedia(this.mediaUrls[this.mediaIndex]);
            }

            this.setFeedback();
          });
      });


  }
}

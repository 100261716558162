<!-- UPLOAD: -->
<!-- <mat-card>
  <form [formGroup]="uploadForm">
    <mat-card-title>Upload</mat-card-title>
    <mat-form-field appearance="fill">
      <mat-label>Enter media title </mat-label>
      <input type="text" matInput formControlName="mediaTitle" (input)="onTitleInput()"> <br>
    </mat-form-field>
    <ng-container *ngIf="!isSupportedFile">
      <span class="unsupportedFileType">Unsupported Media Type. Extension file should be
        <ng-container *ngFor="let mimeType of acceptedMimeType">
          <span> {{ mimeType }}</span>
          <span class="mimeTypeComma">, </span>
        </ng-container>
      </span>
    </ng-container>
    <br>
    <input type="file" accept="video/mp4" (change)="uploadMedia($event)" formControlName="fileToUpload">
    <ng-container *ngIf="uploadPercent > 0">
      <mat-progress-bar mode="determinate" [value]="uploadPercent"></mat-progress-bar>
    </ng-container>
  </form>
</mat-card> -->

<!-- <mat-card> -->
  <!-- <mat-card-title>Media sequencies</mat-card-title> -->
  <!-- Affichage des média disponibles dans storage : -->
  <!-- <div class="availableMedia">
    <mat-card-subtitle>Available media :</mat-card-subtitle>
    <mat-chip-list>
      <div *ngFor="let mediaName of mediaNamesList$ | async">
        <mat-basic-chip color="primary" (removed)="deleteMediaDB(mediaName)">
          {{ mediaName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-basic-chip>
      </div>
    </mat-chip-list>
  </div> -->
  <br>

  <!-- Définir la séquence des média par participant : -->
  <!-- <mat-card-subtitle>Set sequencies :</mat-card-subtitle> -->
  <ng-container *ngFor="let sequency of mediaSequenciesLocal; let i=index; trackBy:trackByIdx">
    <mat-card fxLayout="column">
      <div class="participantUrlLink">
        <div>
          <label>Participant #{{ i + 1 }}</label>
          <!-- <button mat-raised-button color="warn" class="btn delete" (click)="onClearDataByUser(i)">Clear Test
            Data</button> -->
        </div>
        <div class="links">
          <div>
            <span>Webcam and screen recording url : </span>
            <input disabled [value]="recordingLinkToBeCopied[i]" (click)="$event.target.select()">
            <button matTooltip="Copy" mat-raised-button [cdkCopyToClipboard]="recordingLinkToBeCopied[i]"
              (click)="onLinkCopy()"><mat-icon>content_copy</mat-icon></button>
          </div>
          <!-- <div>
            <span>Uxstimuli test url : </span>
            <input disabled [value]="linkToBeCopied[i]" (click)="$event.target.select()">
            <button mat-raised-button class="btn-copy" [cdkCopyToClipboard]="linkToBeCopied[i]"
              (click)="onLinkCopy()">Copy</button>
          </div> -->
        </div>
      </div>
      <!-- <mat-card-subtitle>Media sequency:</mat-card-subtitle>
      <input [(ngModel)]="mediaSequenciesLocal[i]">
      <div>
        <button mat-raised-button color="primary" class="btn save" (click)="onSave(i)">Save</button>
      </div>

      <mat-card-subtitle>Inputs sequency:</mat-card-subtitle>
      <inputs-sequencies [projectId]="projectId" [participant]="i+1" [availableInputs]="availableInputs">
      </inputs-sequencies> -->

    </mat-card>
  </ng-container>
<!-- </mat-card> -->
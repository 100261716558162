import { Component, Input, OnInit, Output, EventEmitter,OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputsService } from '@services/inputs/inputs.service';

@Component({
  selector: 'multi-form',
  templateUrl: './multi-form.component.html',
  styleUrls: ['./multi-form.component.css']
})
export class MultiFormComponent implements OnInit, OnChanges {

  @Input() inputFormData;
  @Output() inputErrors = new EventEmitter();
  errors: Array<string> = [];
  valuesList: Array<string> = [];
  isExistingInputCode;
  isExistingInputName;
  numberOfOptions: number =0;
  options = [];

  constructor(
    private inputsService: InputsService,
    private _snackBar: MatSnackBar
  ) {
    for(let i=0;i<this.options.length;i++){
      this.options[i]="";
    }
   }
   ngOnChanges(changes: SimpleChanges){
     if(changes.options)
      this.options = changes.options.currentValue;
     if(changes.numberOfOptions)
     this.numberOfOptions = changes.numberOfOptions.currentValue;
   }
  ngOnInit(): void { }

  async onSave() {
    let snackMsg;
    let snackbarColor = "grey";

    this.errors = []
    this.inputErrors.emit(this.errors);

    //edit, inputId exists :
    if (this.inputFormData['inputId'] != undefined && this.inputFormData['inputName'] != undefined && this.inputFormData['inputCode'] != undefined) {
      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputCode'], 'inputCode', this.inputFormData['inputId'])
        .then(isExistingCode => {
          this.isExistingInputCode = isExistingCode;
        });

      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputName'], 'inputName', this.inputFormData['inputId'])
        .then(isExistingName => {
          this.isExistingInputName = isExistingName;
        });

      if (!this.isExistingInputCode && !this.isExistingInputName) {
        //modifier document dans firestore :
        this.inputsService.updateInput(this.inputFormData['inputId'], this.inputFormData)
        snackMsg = "Input updated.";

      } else {
        this.errors['inputCode'] = this.isExistingInputCode ? "This input code already exists" : "";
        this.errors['inputName'] = this.isExistingInputName ? "This input name already exists" : "";
        this.inputErrors.emit(this.errors);
        snackMsg = "Error in input code or name.";
        snackbarColor = "mat-warn";
      }
    }
    //nouvel input
    else if (this.inputFormData['inputName'] != undefined && this.inputFormData['inputCode'] != undefined) {
      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputCode'], 'inputCode')
        .then(isExistingCode => {
          this.isExistingInputCode = isExistingCode;
        });

      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputName'], 'inputName')
        .then(isExistingName => {
          this.isExistingInputName = isExistingName;
        });


      if (!this.isExistingInputCode && !this.isExistingInputName) {
        // créer document dans firestore :
        this.inputsService.addInput(this.inputFormData).then(docRef => {
          this.inputFormData['inputId'] = docRef.id;
          this.inputsService.updateInput(docRef.id, { inputId: docRef.id })
        })
        snackMsg = "Input created."
      } else {
        this.errors['inputCode'] = this.isExistingInputCode ? "This input code already exists" : "";
        this.errors['inputName'] = this.isExistingInputName ? "This input name already exists" : "";
        this.inputErrors.emit(this.errors);
        snackMsg = "Error in input code or name.";
        snackbarColor = "mat-warn";
      }
    }
    else {
      snackMsg = "Missing required field(s).";
      snackbarColor = "mat-warn";
    }

    //Affichage du pop-up de confirmation :
    this._snackBar.open(snackMsg, '', {
      duration: 3000,
      panelClass: ['mat-toolbar', snackbarColor]
    });

  }
  onNumberOfOptionsChange(){
    this.inputFormData['numberOfOptions'] = this.numberOfOptions;
  }
  onOptionChange(index){
    if(!this.inputFormData['options'])
      this.inputFormData['options']=[];

    this.inputFormData['options'][index] = this.options[index];
  }
}

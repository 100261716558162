<mat-expansion-panel (opened)="openPanel()" (closed)="closePanel()">

  <mat-expansion-panel-header>
    <mat-panel-title>
      Inputs
    </mat-panel-title>
    <mat-panel-description>
      {{panelOpenState ? '' : 'Click to view'}}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-container *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="mediaSequency.length > 0">
      <mat-tab-group>
        <ng-container *ngFor="let media of mediaSequency; index as indexMedia">
          <mat-tab label="{{ media }}">
            <ng-template matTabContent>
              <div class="media-text">
                <mat-form-field class="media-text">
                  <mat-label>Media Text:</mat-label>
                  <textarea matInput [(ngModel)]="mediaTexts[indexMedia]"
                    placeholder="Ex: Video description"></textarea>
                </mat-form-field>
              </div>
              <h4>Inputs:</h4>
              <!-- INPUTS LIST -->
              <ng-container *ngIf="inputsSequency.length > 0">
                <mat-list role="list">
                  <ng-container *ngFor="let input of inputsSequency[indexMedia]; index as indexInputList">
                    <div class="list-line">
                      <mat-list-item role="listitem">
                        {{ indexInputList + 1 }}. {{ input }}
                      </mat-list-item>
                      <div class="list-buttons">
                        <span class="material-icons" (click)="onClickInputUp(indexMedia,indexInputList)">
                          keyboard_arrow_up
                        </span>
                        <span class="material-icons" (click)="onClickInputDown(indexMedia,indexInputList)">
                          keyboard_arrow_down
                        </span>
                        <span class="material-icons" (click)="onClickInputDelete(indexMedia,indexInputList)">
                          close
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </mat-list>
              </ng-container>

              <!-- SELECT INPUTS -->
              <ng-container *ngIf="mediaSequency.length > 0">
                <mat-form-field appearance="fill" class="inputs-select" [formGroup]="selectForm">
                  <mat-label>Select inputs for this media:</mat-label>
                  <mat-select [(value)]="selectedInput" (selectionChange)="onQuestionSelect(indexMedia)"
                    formControlName="selectedInputValue">
                    <ng-container *ngFor="let input of availableInputs">
                      <mat-option value="{{ input['inputName'] }}"> {{ input['inputName'] }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </ng-template>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div>
        <button mat-raised-button color="primary" class="btn save" (click)="onSaveInputsSeq()"
          [disabled]="isSaving">Save</button>
      </div>
    </ng-container>
  </ng-container>
  <!-- TABS -->

</mat-expansion-panel>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { GoogleChartsModule } from 'angular-google-charts';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxChartsModule } from '@swimlane/ngx-charts';

import {HttpTimeInterceptor} from '@interceptors/http-time-interceptor';
// AngularFire - Firebase
import { AngularFireModule } from '@angular/fire';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';

// Angular Material Module
import { MaterialModule } from './material-module';
// App components
import { AppComponent } from './app.component';
import { FetchPageComponent } from '@components/pages/fetch/fetch.component';

import { MaterialElevationDirective } from '@components/pages/fetch/elevation.directive';

import {MatTableModule } from '@angular/material/table';

import { DashComponent } from '@components/pages/dash/dash.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
// App Services

import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

// Google Analaytics
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { Environment } from '@environments/environment';
import { MatRadioModule } from "@angular/material/radio";
import { SequenciesComponent } from '@components/pages/dash/sequencies/sequencies.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ExportComponent } from '@components/pages/dash/export/export.component';
import { InputsComponent } from '@components/pages/dash/inputs/inputs.component';
import { SliderComponent } from '@components/pages/inputs-views/slider/slider.component';
import { YesOrNoQuestionComponent } from '@components/pages/inputs-views/yes-or-no-question/yes-or-no-question.component';
import { MultipleChoiceQuestionComponent } from '@components/pages/inputs-views/multiple-choice-question/multiple-choice-question.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { InputFormComponent } from './components/pages/dash/inputs/input-form/input-form.component';
import { SliderFormComponent } from './components/pages/dash/inputs/slider-form/slider-form.component';
import { MultiFormComponent } from './components/pages/dash/inputs/multi-form/multi-form.component';
import { YesNoFormComponent } from './components/pages/dash/inputs/yes-no-form/yes-no-form.component';
import { VideoComponent } from './components/pages/fetch/media/video/video.component';
import { InputsSequenciesComponent } from './components/pages/dash/sequencies/inputs-sequencies/inputs-sequencies.component';
import { InputDisplayManagerComponent } from './components/pages/fetch/input-display-manager/input-display-manager.component';
import { MessagesComponent } from '@components/pages/dash/messages/messages.component';
import { RecordingComponent } from './components/pages/fetch/recording/recording.component';
import { RecordMarkersComponent } from './components/pages/dash/record-markers/record-markers.component';
import { MatTooltipModule } from '@angular/material/tooltip';

export function getAppName() {
  return 'fetch';
}

@NgModule({
  imports: [
    NgxCurrencyModule,
    GoogleChartsModule,
    BrowserModule,
    AngularFireModule.initializeApp(Environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,//NoopAnimationsModule,//
    MaterialModule,
    FlexLayoutModule,
    AngularFireFunctionsModule,
    HttpClientJsonpModule,
    MatRadioModule,
    NgxChartsModule,
    MatProgressBarModule,
    MatTableModule,
    ClipboardModule,
    MatTooltipModule,
    NgxAuthFirebaseUIModule.forRoot(Environment.firebase, undefined, {
      toastMessageOnAuthSuccess: false
    })
  ],
  declarations: [
    MaterialElevationDirective, //todo: remove
    AppComponent,
    FetchPageComponent,
    DashComponent,
    SequenciesComponent,
    ExportComponent,
    InputsComponent,
    SliderComponent,
    YesOrNoQuestionComponent,
    MultipleChoiceQuestionComponent,
    InputFormComponent,
    SliderFormComponent,
    MultiFormComponent,
    YesNoFormComponent,
    VideoComponent,
    InputsSequenciesComponent,
    InputDisplayManagerComponent,
    MessagesComponent,
    RecordingComponent,
    RecordMarkersComponent,
  ],
  entryComponents: [
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: FIRESTORE_SETTINGS,
      useValue: Environment.production ? undefined : {
        host: 'localhost:8080',
        ssl: false,
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTimeInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

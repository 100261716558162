<!-- START VALUE -->
<!-- <mat-form-field appearance="fill">
  <mat-label>Start Value</mat-label>
  <mat-select [(ngModel)]="inputFormData['value']" name="value" required>
    <mat-option *ngFor="let value of valuesList" [value]="value">
      {{ value }}
    </mat-option>
  </mat-select>
</mat-form-field><br> -->
<mat-form-field appearance="fill">
  <mat-label>Yes - label </mat-label>
  <input matInput [(ngModel)]="inputFormData['inputYesText']" name="inputYesText">
</mat-form-field><br>
<mat-form-field appearance="fill">
  <mat-label>No - label </mat-label>
  <input matInput [(ngModel)]="inputFormData['inputNoText']" name="inputNoText">
</mat-form-field><br>


<button mat-raised-button color="primary" (click)="onSave()">Save</button>
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';
import { SequenciesService } from '@services/sequencies/sequencies.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'inputs-sequencies',
  templateUrl: './inputs-sequencies.component.html',
  styleUrls: ['./inputs-sequencies.component.css']
})
export class InputsSequenciesComponent implements OnDestroy {

  @Input() projectId;
  @Input() participant;
  @Input() availableInputs: Array<any>;
  mediaSequency: Array<string> = [];
  mediaTexts: Array<string> = [];
  inputsSequency: Array<Array<string>> = [];
  selectedInput;
  currentDocumentId;
  selectForm = new FormGroup({
    selectedInputValue: new FormControl('')
  });
  panelOpenState = false;
  getSequenciesSub: Subscription;
  isSaving = false;
  isLoading = false;

  constructor(
    private sequenciesService: SequenciesService,
    private inputsService: InputsService,
    private _snackBar: MatSnackBar,
  ) { }

  openPanel() {
    this.panelOpenState = true;
    this.isLoading = true;
    this.inputViewOpen();
  }

  closePanel() {
    this.panelOpenState = false;
  }

  inputViewOpen(): void {
    // this.currentDocumentId = "";
    // this.mediaSequency = [];
    // this.mediaTexts = [];
    // this.availableInputs = [];
    // console.log("[ON INIT]", changes)
    // if(changes.projectId && changes.participant){
    // if(changes.projectId.currentValue!=changes.projectId.previousValue || changes.participant.currentValue!=changes.participant.previousValue){
    //Récupérer la séquence de média du participant, pour l'affichage des tabs :
    this.getSequenciesSub = this.sequenciesService.getSequency(this.participant, this.projectId).subscribe(async sequency => {
      this.isLoading = true;
      console.log("[Subscription]: Get Sequency ")
      if (sequency.length != 0) {
        this.currentDocumentId = sequency[0]['sequencyDocumentId'];
        this.mediaSequency = sequency[0]['mediaSequency'];
        this.mediaTexts = sequency[0]['mediaText'];
        if (!this.mediaTexts)
          this.mediaTexts = [];

        for (let indexMedia in sequency[0]['inputsSequency']) {
          this.inputsSequency[indexMedia] = []
          if (sequency[0]['inputsSequency'][indexMedia] != undefined) {
            for (let inputDocId of sequency[0]['inputsSequency'][indexMedia]) {
              this.inputsSequency[indexMedia].push(
                await this.inputsService.getInputNameWithDocId(inputDocId)
              )
            }
          }
        }
      } else {
        this.mediaSequency = [];
      }
      this.isLoading = false;
    });


    // }
    // }
  }

  onQuestionSelect(indexMedia) {
    if (!this.inputsSequency[indexMedia]) {
      for (let mediaIndex in this.mediaSequency) {
        this.inputsSequency[mediaIndex] = []
      }
    }
    this.inputsSequency[indexMedia].push(this.selectedInput);
    this.selectForm.reset();
  }

  onClickInputUp(indexMedia, indexInputList) {
    if (indexInputList > 0) {
      this.inputsSequency[indexMedia][indexInputList - 1] = this.inputsSequency[indexMedia].splice(indexInputList, 1, this.inputsSequency[indexMedia][indexInputList - 1])[0];
    }
  }

  onClickInputDown(indexMedia, indexInputList) {
    if (indexInputList <= this.inputsSequency.length) {
      this.inputsSequency[indexMedia][indexInputList] = this.inputsSequency[indexMedia].splice(indexInputList + 1, 1, this.inputsSequency[indexMedia][indexInputList])[0];
    }
  }

  onClickInputDelete(indexMedia, indexInputList) {
    this.inputsSequency[indexMedia].splice(indexInputList, 1)
  }

  async onSaveInputsSeq() {
    this.isSaving = true;
    let selectedInputDocId = [];
    let formatedInputSequency = []
    for (let indexMedia in this.mediaSequency) {
      selectedInputDocId[indexMedia] = [];
      for (let inputName of this.inputsSequency[indexMedia]) {
        selectedInputDocId[indexMedia].push(
          await this.inputsService.getDocIdWithInputName(inputName, this.projectId)
        )
      }
      if (this.mediaTexts[indexMedia]) {
        formatedInputSequency.push(
          {
            name: this.mediaSequency[indexMedia],
            text: this.mediaTexts[indexMedia],
            inputs: selectedInputDocId[indexMedia]
          }
        )
      } else {
        formatedInputSequency.push(
          {
            name: this.mediaSequency[indexMedia],
            inputs: selectedInputDocId[indexMedia]
          }
        )
      }
    }
    this.sequenciesService.updateSequency(this.currentDocumentId, { sequency: formatedInputSequency }).then(_ => {
      this.isSaving = false;
      //Affichage du pop-up indiquant que la séquence d'inputs a bien été modifiée :
      this._snackBar.open('Input Sequency saved.', '', {
        duration: 3000
      });
    });

    this.selectForm.reset();
  }

  ngOnDestroy() {
    console.log('[On destroy] inputs-sequencies.component.ts');
    if (this.getSequenciesSub) this.getSequenciesSub.unsubscribe();
  }
}

import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SequenciesService } from '@services/sequencies/sequencies.service';
import { Subject, Subscription } from 'rxjs';
import { finalize, throttleTime } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService } from '@services/feedback/feedback.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '@services/storage/storage.service';
import { InputsService } from '@services/inputs/inputs.service';
import { Environment } from '@environments/environment';

@Component({
  selector: 'sequencies',
  templateUrl: './sequencies.component.html',
  styleUrls: ['./sequencies.component.css']
})
export class SequenciesComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  @Input() userId: string;

  mediaSequenciesLocal = [];
  isExistingMediaSequency = [];
  originalMediaSequencies = [];
  mediaSequenciesDocumentId = [];
  mediaNamesList = [];
  mediaTextsList = [];
  mediaNamesList$: Subject<any>;
  uploadForm: FormGroup;
  uploadPercent: number;
  acceptedMimeType: Array<string> = [".mp4"/*, ".jpg", ".png", ".mp3", ".ogg"*/];
  isSupportedFile: boolean = true;
  documentUrl: string; //url de l'app
  linkToBeCopied: Array<string> = [];
  recordingLinkToBeCopied: Array<string> = [];
  isExistingFeebacks = []; //variable pour vérifier s'il existe un ou des feeback(s) pour un userId et un projet donnés
  availableInputs: Array<any>;
  getAvailableInputsSub: Subscription;
  getSequenciesSub: Subscription;
  getUploadedFilesSub : Subscription;

  constructor(
    private sequenciesService: SequenciesService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private storageService: StorageService,
    private feedbackService: FeedbackService,
    private inputsService: InputsService
  ) {
    //formulaire pour l'upload :
    this.uploadForm = new FormGroup(
      {
        mediaTitle: new FormControl(),
        fileToUpload: new FormControl({ value: '', disabled: true }),
      }
    );
    this.mediaNamesList$ = new Subject();
  }

  ngOnInit(): void {
    // this.documentUrl = document.URL.slice(0, -"dash".length);
    this.documentUrl = Environment.url;
    // console.log("[ON INIT]: Sequencies")
    for (let i = 0; i < 100; i++) {
      /* générer des urls d'accès uxstimuli: */
      this.linkToBeCopied[i] = this.documentUrl + "?id=" + (i + 1) + "&project=" + this.projectId;

      /* générer des urls d'accès au monitoring : */
      this.recordingLinkToBeCopied[i] = this.documentUrl + "record/?id=" + (i + 1) + "&project=" + this.projectId + "&owner=" + this.userId;

      /* récupérer les séquences dans la db : */
      this.mediaSequenciesLocal[i] = [];
      //i+1 car il n'y a pas de participant #0:
      this.getSequenciesSub = this.sequenciesService.getSequency(i + 1, this.projectId).subscribe(mediaSequenciesDocumentDB => {
        // console.log("[Subscription]: Get Sequency",mediaSequenciesDocumentDB)
        //si la séquence est déjà définie dans la base de données :
        if (mediaSequenciesDocumentDB[0] != undefined) {
          this.isExistingMediaSequency[i] = true;
          //récupère la séquence (array) et la passer en chaine de caractères :
          let mediaSequenciesDocument = mediaSequenciesDocumentDB[0];
          this.mediaSequenciesLocal[i] = mediaSequenciesDocument.mediaSequency.toString();
          //conserver la séquence initiale dans une variable :
          this.originalMediaSequencies[i] = this.mediaSequenciesLocal[i];
          //conserver le document id firestore de la séquence :
          this.mediaSequenciesDocumentId[i] = mediaSequenciesDocument.sequencyDocumentId;
        } else {
          this.isExistingMediaSequency[i] = false; // si aucune séquence vidéo n'est spécifiée, message d'erreur
          this.mediaSequenciesLocal[i] = "";
        }
      })
    }

    this.setListFilesNames();

    //récupérer les inputs disponibles pour ce projet pour l'affichage du select :
    this.getAvailableInputsSub = this.inputsService.getInputsByProject(this.projectId).subscribe(availableInputs => {
      console.log("[Subscription]: Get Input ")

      this.availableInputs = [];
      for (let input of availableInputs) {
        this.availableInputs.push(input['inputData'])
      }
    })
  }

  /**
   * Donner accès au bouton d'upload de fichier seulement si un titre de vidéo est entré
   */
  onTitleInput() {
    if (this.uploadForm.get('mediaTitle').value.length > 0) {
      this.uploadForm.get('fileToUpload').enable()
    } else {
      this.uploadForm.get('fileToUpload').disable()
    }
  }

  /**
   * Enregistrer une séquence dans firebase
   * @param i : index du participant (=numéro du participant - 1)
   */
  onSave(i) {
    /* vérifier s'il y a des feebacks dans la db pour un userId et un projet donnés : */
    this.feedbackService.getFeedbacksIdsByUserAndProject(i + 1, this.projectId).then(existingsFeedbacks => {
      if (existingsFeedbacks.length > 0) this.isExistingFeebacks[i] = true;
      else this.isExistingFeebacks[i] = false;

      if (!this.isExistingFeebacks[i]) {
        this.storageService.getUploadedFiles(this.projectId, "/media").toPromise()
          .then(res => {
            this.mediaSequenciesLocal[i] = this.mediaSequenciesLocal[i].replace(' ', ''); //supprime les éventuels espaces avant la virgule
            let updatedMediaSequency = this.mediaSequenciesLocal[i].split(",");
            let mediaSequencyCheckMsg;
            let nonIncludedMediaCounter = 0;
            let mediaDB = [];
            let snackbarColor;

            //récupérer les noms des vidéos sur storage, enlever le nom du répertoire avant le nom du fichier (slice) et mettre les noms dans un tableau:
            for (let item of res.items) {
              mediaDB.push(item['location']['path'].slice(this.storageService.repository.length + "/media".length));
            }

            //pour chaque nom de vidéo entré dans l'input, vérifier qu'il existe dans storage :
            for (let mediaNameInput of updatedMediaSequency) {
              if (!mediaDB.includes(mediaNameInput)) {
                nonIncludedMediaCounter++;
              }
            }

            //Si un des noms de vidéo entré dans l'input n'existe pas sur storage et que l'input n'est pas vide :
            if (nonIncludedMediaCounter > 0 && updatedMediaSequency[0] != "") {
              mediaSequencyCheckMsg = "Error in sequency syntax.";
              snackbarColor = "mat-warn";
              this.mediaSequenciesLocal[i] = this.originalMediaSequencies[i];
            } else {
              //si tous les noms de vidéos entrés dans l'input existent dans Storage :
              if (updatedMediaSequency[0] != "") {
                mediaSequencyCheckMsg = "You entered " + updatedMediaSequency.length + " items(s) for participant #" + (i + 1) + " sequency.";
              }
              //si l'input est vide :
              else if (updatedMediaSequency[0] == "") {
                mediaSequencyCheckMsg = "No item entered for participant #" + (i + 1) + " sequency.";
              }

              snackbarColor = "grey";

              //Mettre la séquence à enregistrer au bon format (tableau de map)
              let formatedMediaSequency = [];
              for (let media of updatedMediaSequency) {
                formatedMediaSequency.push(
                  {
                    name: media,
                    inputs : []
                  }
                )
              }

              //si la séquence est déjà définie dans storage on l'update, sinon on l'ajoute :
              if (this.isExistingMediaSequency[i]) {
                this.sequenciesService.updateSequency(this.mediaSequenciesDocumentId[i], { sequency: formatedMediaSequency });
              } else {
                this.sequenciesService.addSequency((i + 1).toString(), this.projectId, formatedMediaSequency)
              }
            }

            //Affichage du pop-up indiquant combien de vidéos il y a dans la séquence :
            this._snackBar.open(mediaSequencyCheckMsg, '', {
              duration: 3000,
              panelClass: ['mat-toolbar', snackbarColor]
            });
          })
      } else {
        //Affichage du pop-up indiquant combien de vidéos il y a dans la séquence :
        let mediaSequencyCheckMsg = 'Some feedbacks already exist in database for this participant. You have to delete them before saving a new sequency.'
        this._snackBar.open(mediaSequencyCheckMsg, '', {
          duration: 4000,
          panelClass: ['mat-toolbar', "mat-warn"]
        });
        //remettre la séquence originale dans l'input :
        this.mediaSequenciesLocal[i] = this.originalMediaSequencies[i];
      }
    })

  }

  /**
   * Message de confirmation de copie du lien dans le clipboard
   */
  onLinkCopy() {
    this._snackBar.open("Link copied to clipboard", '', {
      duration: 2000,
    });
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  /**
   * Mettre en place un observable pour avoir les noms des vidéos uploadées dans le répertoire "/media" :
   */
  setListFilesNames() {
    this.getUploadedFilesSub = this.storageService.getUploadedFiles(this.projectId, "/media").subscribe(res => {
      for (let item of res.items) {
        //item['location']['path'] = nom du fichier dans storage.
        //slice pour enlever le nom du répertoire avant le nom de la vidéo, this.storageService.environment = nom du répertoire
        this.mediaNamesList.push(item['location']['path'].slice(this.storageService.repository.length + "/media".length));
      }
      this.mediaNamesList$.next(this.mediaNamesList);
    })
  }

  /**
   * Upload un média sur firestorage, dans le dossier /media
   * @param event
   */
  uploadMedia(event) {
    let file = event.target.files[0];
    let fileExtension = file.name.slice(-4);
    // console.log("Mime type : ", fileExtension);
    let mediaTitle = this.uploadForm.get("mediaTitle").value;
    if (mediaTitle.slice(-4) != fileExtension) {
      mediaTitle += fileExtension;
    }
    let filePath = "/media/" + mediaTitle.replace(' ', '_'); //remplacer les espaces dans le titre par "_"

    //si l'extension du fichier chargé est dans la liste des extensiosn acceptées, on upload dans storage, sinon message d'erreur :
    // if (this.acceptedMimeType.indexOf(fileExtension) >= 0) {
    //   this.isSupportedFile = true;

    //   const task = this.storageService.uploadFile(this.projectId, filePath, file);

    //   //Affichage bar de progression de l'upload :
    //   task.percentageChanges().pipe(
    //     throttleTime(500), //pour éviter les saccades
    //   )
    //     .subscribe(uploadPercent => {
    //       this.uploadPercent = uploadPercent;
    //     })

    //   //Reset à la fin de l'upload :
    //   task.snapshotChanges().pipe(
    //     finalize(() => {
    //       this.setListFilesNames();
    //       this.uploadPercent = 0;
    //       this.uploadForm.reset();
    //       this.uploadForm.get('fileToUpload').disable()
    //       this.mediaNamesList = [];
    //     })
    //   ).subscribe()
    // } else {
    //   this.isSupportedFile = false;
    // }
  }

  /**
   * Suppression du media de storage
   * @param mediaName
   */
  deleteMediaDB(mediaName) {
    /* supprimer la vidéo du tableau utilisé pour l'itération d'affichage : */
    const index = this.mediaNamesList.indexOf(mediaName);
    this.mediaNamesList.splice(index, 1);

    /* Suppression du media dans storage, dans le dossier "/media" du projet : */
    this.storageService.getUploadedFiles(this.projectId, "/media").subscribe(data => {
      data.items.forEach(item => {
        //slice pour enlever le nom du répertoire au début :
        if (item['location']['path'].slice(this.storageService.repository.length + "/media".length) == mediaName) {
          // this.storage.storage.ref(item['location']['path']).delete()
          this.storageService.deleteFile(item['location']['path']);
        }
      })
    })
  }

  /**
   * Ouverture du dialog de confirmation lors de la suppression de données de test pour un participant
   * @param userIndex
   */
  onClearDataByUser(userIndex) {
    this.dialog.open(ClearDataDialog, {
      data: {
        userIndex: userIndex,
        projectId: this.projectId
      }
    });
  }

  ngOnDestroy(){
    console.log('[On destroy] sequencies.component.ts');
    if(this.getAvailableInputsSub) this.getAvailableInputsSub.unsubscribe();
    if(this.getSequenciesSub) this.getSequenciesSub.unsubscribe();
    if(this.getUploadedFilesSub) this.getUploadedFilesSub.unsubscribe();
  }
}

//////////////////////////////////////////////////
//Dialog to confirm participant data deletion : //
//////////////////////////////////////////////////
@Component({
  selector: 'clear-data-dialog',
  templateUrl: 'clear-data-dialog.html',
})
export class ClearDataDialog {

  constructor(
    public dialogRef: MatDialogRef<any>,
    private feedbackService: FeedbackService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  /**
   * Suppression des données d'un participant au clic sur "confirm"
   */
  onConfirm(): void {
    let userFeedbackIds = this.feedbackService.getFeedbacksIdsByUserAndProject(this.data.userIndex + 1, this.data.projectId);

    userFeedbackIds.then(feedbackIds => {
      if (feedbackIds.length === 0) this.dialogRef.close(); //cas où il n'y a pas de feedback pour cet utilisateur
      for (let feedbackId of feedbackIds) {
        this.feedbackService.clearFeedback(feedbackId);
        this.dialogRef.close();
      }
    })
  }

  /**
   * Retour à la page de settings au clic sur "cancel"
   */
  onCancel() {
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { firestore } from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  feedbackCollection: AngularFirestoreCollection<any>;
  allProjects$: Observable<any>;

  constructor(
    private afs: AngularFirestore,
  ) { }

  getProjects(userId) {
    this.allProjects$ = this.afs.collection<any>('projects', ref => ref.where('user_id', "==", userId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const projectDocumentId = a.payload.doc.id;
        const projectData = a.payload.doc.data();
        return { projectDocumentId, projectData };
      }))
    );
    return this.allProjects$;
  }

  addProject(projectName, userId) {
    console.log('nom: ', projectName, 'userId: ', userId);
    return this.afs.collection('projects').add(
      {
        projectName: projectName,
        user_id: userId
      }
    )
  }
}

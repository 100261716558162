import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessagesService } from '@services/messages/messages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnDestroy {

  @Input() projectId;
  messagesDocId = '';
  alreadyCompleted;
  experimentationEnd;
  noStimuli;
  nextButton;
  mediaSharing;
  mediaSharingEnd;
  getMessagesSub: Subscription;

  constructor(
    private messagesService: MessagesService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getMessagesSub = this.messagesService.getMessages(this.projectId).subscribe(messages => {
      if (messages.length != 0) {
        this.alreadyCompleted = messages[0]['messagesData']['alreadyCompleted'];
        this.experimentationEnd = messages[0]['messagesData']['experimentationEnd'];
        this.noStimuli = messages[0]['messagesData']['noStimuli'];
        this.nextButton = messages[0]['messagesData']['nextButton'];
        this.mediaSharing = messages[0]['messagesData']['mediaSharing'];
        this.mediaSharingEnd = messages[0]['messagesData']['mediaSharingEnd'];
        this.messagesDocId = messages[0]['messagesDocumentId'];
      }
    })
  }

  onSave() {
    //nouveau :
    if (this.messagesDocId == '') {
      this.messagesService.addMessages(
        {
          projectId: this.projectId,
          alreadyCompleted: this.alreadyCompleted,
          experimentationEnd: this.experimentationEnd,
          noStimuli: this.noStimuli,
          nextButton: this.nextButton,
          mediaSharing: this.mediaSharing,
          mediaSharingEnd: this.mediaSharingEnd
        }
      )

      //Affichage du pop-up indiquant que les messages ont bien été enregistrés :
      this._snackBar.open('Messages saved.', '', {
        duration: 3000
      });
    }
    //edit :
    else {
      this.messagesService.updateMessages(this.messagesDocId,
        {
          projectId: this.projectId,
          alreadyCompleted: this.alreadyCompleted,
          experimentationEnd: this.experimentationEnd,
          noStimuli: this.noStimuli,
          nextButton: this.nextButton,
          mediaSharing: this.mediaSharing,
          mediaSharingEnd: this.mediaSharingEnd
        })

      //Affichage du pop-up indiquant que les messages ont bien été modifiés :
      this._snackBar.open('Messages saved.', '', {
        duration: 3000
      });
    }
  }

  ngOnDestroy(){
    console.log('[On destroy] messages.component.ts');
    if(this.getMessagesSub) this.getMessagesSub.unsubscribe();
  }
}

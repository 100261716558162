import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { first, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InputsService {
  private inputsCollection: AngularFirestoreCollection<any>;
  isExistingAttribute;
  inputDocId;
  inputName;

  constructor(private afs: AngularFirestore) {
    this.inputsCollection = this.afs.collection<any>('inputs');
  }

  getInputsByProject(projectId) {
    return this.afs.collection('inputs', ref => ref.where('projectId', "==", projectId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const inputDocumentId = a.payload.doc.id;
        const inputData = a.payload.doc.data();
        return { inputDocumentId, inputData };
      }))
    );
  }

  async isExistingAttributeVal(projectId, attributeVal, attribute, inputId = '') {
    await this.afs.collection('inputs', ref => ref.where(attribute, '==', attributeVal).where('projectId', '==', projectId)).snapshotChanges().pipe(
      map(actions => actions
        .map(a => {
          return a.payload.doc.data();
        })))
      .pipe(take(1)).toPromise().then(inputsData => {
        //s'il existe une déjà cette valeur pour l'attribut, pour un input différent de celui que l'on veut modifier
        if (inputsData.length > 0 && inputsData[0]['inputId'] != inputId)
          this.isExistingAttribute = true;
        else
          this.isExistingAttribute = false;
      });
    return this.isExistingAttribute;
  }

  getInput(inputId) {
    return this.afs.doc('inputs/' + inputId).valueChanges().pipe(first());
  }

  getInputByProject(projectId) {
    return this.afs.collection('inputs', ref => ref.where('projectId', '==', projectId)).valueChanges();
  }

  addInput(input: any) {
    return this.inputsCollection.add(input);
  }

  updateInput(documentId, inputData) {
    return this.afs.collection('inputs').doc(documentId).update(inputData);
  }

  getInputInitialValues(inputId, projectId) {
    return this.afs.collection('inputs', ref => ref.where('inputId', '==', inputId).where('projectId', '==', projectId)).snapshotChanges().pipe(
      map(actions => actions
        .map(a => {
          const inputDocumentId = a.payload.doc.id;
          const inputData = a.payload.doc.data();
          return { inputDocumentId, inputData };
        }))
    );
  }

  async getDocIdWithInputName(inputName, projectId) {
    await this.afs.collection('inputs', ref => ref.where('inputName', '==', inputName).where('projectId', '==', projectId)).valueChanges()
      .pipe(take(1)).toPromise().then(inputVal => {
        if (inputVal[0] != undefined) {
          this.inputDocId = inputVal[0]['inputId'];
        }
      })
    return this.inputDocId;

  }

  async getInputNameWithDocId(docId = "") {
    await this.afs.collection('inputs', ref => ref.where('inputId', '==', docId)).valueChanges()
      .pipe(take(1)).toPromise().then(inputVal => {
        // console.log(docId, inputVal[0]['inputName'])
        if (inputVal[0] != undefined) {
          this.inputName = inputVal[0]['inputName'];
        }
      })
    return this.inputName;

  }
}

<div class="media-container">
  <video
    class="center"
    [src]="url" (play)="onMediaStart()"
    [controls]="!mediaStarted"
    poster="assets/black-img.png"
    (ended)="onMediaEnd()"
    disablepictureinpicture controlslist="nodownload nofullscreen">
  </video>
</div>
<div *ngIf="mediaEnded" class="video-text">
  <span *ngIf="mediaText" >{{mediaText}}</span>
</div>

<ng-container *ngIf="inputFormData">
  <div class="slider center">
    <p  class="center">{{ inputFormData['inputText'] }}</p>
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <img class="img-slider" [src]="inputFormData['imgLeftUrl']">
      <mat-slider 
        color="primary" 
        [max]="inputFormData['maxValue']" 
        [min]="inputFormData['minValue']"
        [step]="inputFormData['sliderStep']"
        [thumbLabel]="inputFormData['showValue']"
        [(ngModel)]="inputFormData['value']"
        (change)="onFinish()">
      </mat-slider>
      <img class="img-slider" [src]="inputFormData['imgRightUrl']">
    </div>
    <img class="slider-background center" src="assets/aa.png">
  </div>
</ng-container>
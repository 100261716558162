<!-- -------------------- -->
<!-- MESSAGES SPECIFIQUES -->
<!-- -------------------- -->

<!-- fin de l'expérimentation : -->
<ng-container *ngIf="isLastMedia">
  <mat-card class="specificMsg">
    <p>{{ msgExperimentationEnd }}</p>
  </mat-card>
</ng-container>

<!-- Aucun media disponible pour cet utilisateur et ce projet : -->
<ng-container *ngIf="!isMediaSequencySet">
  <mat-card class="specificMsg">
    <p>{{ msgNoStimuli }}</p>
  </mat-card>
</ng-container>

<!-- test déjà complété : -->
<ng-container *ngIf="!firstAttempt && !isLastMedia">
  <mat-card class="specificMsg">
    <p>{{ msgAlreadyCompleted }}</p>
  </mat-card>
</ng-container>


<!-- ----------------- -->
<!-- INTERFACE DE TEST -->
<!-- ----------------- -->
<ng-container *ngIf="firstAttempt && !isLastMedia && isMediaSequencySet">
  <div class="center" fxLayout="column wrap">

    <!-- VIDEO -->
    <ng-container *ngIf="(this.mediaType | slice:0:5) === 'video'">
      <app-video [url]="this.currentUrl" [mediaText]="mediaTexts[mediaIndex]"></app-video>
    </ng-container>

    <!-- IMAGES -->
    <!-- <ng-container *ngIf="(this.mediaType | slice:0:5) === 'image'">
      <div class="media-img-container">
        <img class="media-img" [src]="this.currentUrl">
      </div>
    </ng-container> -->

    <!-- AUDIO -->
    <!-- <ng-container *ngIf="(this.mediaType | slice:0:5) === 'audio'">
      <div class="media-audio-container">
        <audio [src]="this.currentUrl" controls controlslist="nodownload" (play)="onMediaStart()" (ended)="onMediaEnd()"
          [ngClass]="{'audio-started' : mediaStarted}">
          <source type="{{this.mediaType}}">
        </audio>
      </div>
    </ng-container> -->

    <ng-container *ngFor="let inputId of this.inputsSequency[this.mediaIndex] ; index as i">
      <ng-container *ngIf="displayStatus['input'+i]">
        <input-display-manager [inputId]="inputId" [inputIndex]="i" [projectId]="projectId"></input-display-manager>
      </ng-container>
    </ng-container>

    <!-- PREVIOUS/NEXT -->
    <div class="center" fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="displayStatus['nextBtnDisplay']">
      <!-- <button mat-raised-button color="primary" class="btn" (click)="onPrevious()">Previous</button> -->
      <button class="btn next" mat-raised-button color="primary" (click)="onNext()">
        {{ msgNextButton }}
      </button>
    </div>
  </div>
</ng-container>
<div class="filer"></div>

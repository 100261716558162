import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Environment } from '@environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  listRef;
  repository;
  userId;

  constructor(
    private storage: AngularFireStorage,
    public auth: AngularFireAuth
  ) {
    this.auth.authState.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        if (Environment.production) {
          this.repository = "/0000/" + this.userId;
        } else {
          this.repository = "/dev/" + this.userId;
        }
        this.listRef = this.storage.ref(this.repository);
      }
    })
  }

  /* référence de répertoire selon l'environnement sur lequel on travail : */
  // setRepository(projectId) {
  //   if (Environment.production) {
  //     this.repository = "/dev/" + projectId; //le dossier firebase storage en prod est "dev" : erreur lors d'un hotfix. A corriger lors d'un clean up de la base.
  //   } else {
  //     this.repository = "/true_dev/" + projectId;
  //   }
  //   this.listRef = this.storage.ref(this.repository);
  // }

  isRepositoryValid(repository, projectId) {
    if (repository == '/0000/' + this.userId + '/' + projectId) {
      return true;
    } else {
      return false;
    }
  }

  /* Référence du répertoire en cours : */
  // getStorageFileRef(projectId, url) {
  //   return this.storage.ref(this.repository + "/" + projectId + "/" + url);
  // }

  /**
   * Récupérer la liste des fichiers dans un répertoire donné 
   * @param path : répertoire auquel on souhaite accéder
   */
  getUploadedFiles(projectId, path) {
    return this.storage.ref(this.repository + "/" + projectId + path).listAll();
  }

  /**
   * Uploader un fichier sur storage
   * @param filePath 
   * @param file 
   */
  uploadFile(projectId, relativeFilePath, file, ownerId) {
    let filePath = "";
    if (Environment.production) {
      filePath = "/0000/" + ownerId + "/" + projectId + "/" + relativeFilePath;
    } else {
      filePath = "/dev/" + ownerId + "/" + projectId + "/" + relativeFilePath;
    }
    return this.storage.upload(filePath, file)
  }

  /**
   * Supprimer un fichier du storage
   * @param path 
   */
  deleteFile(path) {
    this.storage.storage.ref(path).delete()
  }

  /**
   * Obtenir l'url pour le download
   * @param url 
   * @returns 
   */
  getRecordUrl(url) {
    return this.storage.ref(url).getDownloadURL();
  }
}


import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FeedbackService } from '@services/feedback/feedback.service';
import { InputsService } from '@services/inputs/inputs.service';
import { InputSequencerService } from '@services/inputSequencer/input-sequencer.service';

@Component({
  selector: 'multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.css']
})
export class MultipleChoiceQuestionComponent implements OnInit, OnChanges{

  @Input() inputId;
  @Input() projectId;
  @Input() inputIndex;
  @Input() inputFormData;

  options=[];
  numberOfOptions=0;

  constructor(
    private inputSequencerService: InputSequencerService,
    private inputsService: InputsService,
    private feedbackService: FeedbackService
  ) { }
  ngOnChanges(changes: SimpleChanges){
    if(changes.options)
     this.options = changes.options.currentValue;
    if(changes.numberOfOptions)
    this.numberOfOptions = changes.numberOfOptions.currentValue;
  }
  ngOnInit(): void {
    if (this.inputId && this.projectId) {
      this.inputsService.getInputInitialValues(this.inputId, this.projectId).subscribe(inputVal => {
        this.inputFormData = inputVal[0]['inputData'];
      });
    }
  }

  onFinish() {
    if (this.inputId && this.projectId) {
      let parameterName = 'input' + this.inputIndex;
      this.inputSequencerService.setStatus(parameterName, true);
      this.feedbackService.setFeedbackValues(
        {
          [this.inputFormData['inputCode']]: this.inputFormData["value"]
        }
      );
    }
  }
  }

import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { StreamingService } from '@services/streaming/streaming.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'record-markers',
  templateUrl: './record-markers.component.html',
  styleUrls: ['./record-markers.component.css']
})
export class RecordMarkersComponent implements OnInit, OnDestroy {

  @Input() projectId;
  @ViewChild('videoCamera') videoCamera: ElementRef;
  @ViewChild('videoScreen') videoScreen: ElementRef;
  @ViewChildren('markerInput') markerInputElems: QueryList<ElementRef>; //marker name inputs ref for autofocus
  participantListSub: Subscription;
  recordsListSub: Subscription;
  selectedParticipant: string;
  participantNames: any = [];
  participantRecordsSrc: any = {};
  cameraDurationAvailable = false;
  screenDurationAvailable = false;
  formatedScreenDuration;

  displayedColumns: Array<string> = ["timestamp", "markerName", "actions-col"];
  markers;
  markers$;
  getMarkersSub: Subscription;
  latency;
  previousLatency;
  currentScreenTime;
  formatedCurrentScreenTime;

  streamingDocumentId;
  streamingData;

  estimateVideoDuration;
  estimateProcessingVideoTime;
  estimateProcessingVideoTime$ = new Subject();

  currentSpeed = "1";

  constructor(
    private storageService: StorageService,
    private streamingService: StreamingService
  ) { }

  ngOnInit(): void {
    this.participantListSub = this.storageService.getUploadedFiles(this.projectId, "/records").subscribe(list => {
      console.log('list :', list)
      list["prefixes"].forEach(participant => {
        this.participantNames.push(participant.name);
      });
    });
    this.markers = [];
    this.markers$ = new BehaviorSubject(this.markers);

    //save markers as they are created:
    this.getMarkersSub = this.markers$.subscribe(data => {
      // (console.log("marker changed"))
      this.markers = data;
      if (this.streamingDocumentId) this.saveMarkers();
    })
  }

  reset() {
    this.cameraDurationAvailable = false;
    this.screenDurationAvailable = false;
    this.markers = [];
    this.latency = 0;
  }

  onParticipantSelect() {
    this.reset();

    let participantNumber = Number(this.selectedParticipant.substring(1));

    /* get existing video and markers data : */
    this.streamingService.getStreamingData(this.projectId, participantNumber.toString()).pipe(take(1)).toPromise().then(data => {
      this.streamingDocumentId = data[0]["streamingDocumentId"];
      this.streamingData = data[0]["streamingData"];

      /* estimate video duration */
      this.estimateVideoDuration = this.streamingData["last_chunk_time_camera"] - this.streamingData["started_at_camera"];
      let unformatedEstimateProcessingVideoTime = (this.estimateVideoDuration / 16);
      
      /* estimate remaining time for video processing (play x16) */
      this.estimateProcessingVideoTime = new Date(unformatedEstimateProcessingVideoTime  * 1000).toISOString().substr(11, 8);
      let countdowm = setInterval(_ => {
        this.estimateProcessingVideoTime = new Date(unformatedEstimateProcessingVideoTime* 1000).toISOString().substr(11,8);
        unformatedEstimateProcessingVideoTime -= 1; //countdown: remove 1 seconde of remaining time 
        if(unformatedEstimateProcessingVideoTime <= 0) {
          clearInterval(countdowm);
        }
      }, 1000);

      /* get existing markers: */
      if (this.streamingData['markers']) {
        this.markers = this.streamingData['markers']
      }
      this.markers$.next(this.markers);
    })

    /* get records download urls: */
    this.storageService.getUploadedFiles(this.projectId, "/records/" + this.selectedParticipant).subscribe(recordsList => {
      recordsList["items"].forEach(async record => {
        if (record.name.match(/screen/g)) {
          this.participantRecordsSrc["screen"] = await this.storageService.getRecordUrl(record["location"]["path"]).toPromise();
        } else if (record.name.match(/camera/g)) {
          this.participantRecordsSrc["camera"] = await this.storageService.getRecordUrl(record["location"]["path"]).toPromise();
        }
      });

      //https://bugs.chromium.org/p/chromium/issues/detail?id=642012 : MediaRecorder produces WebM chunks that, when assembled, produce a WebM file that cannot be seeked and does not have correct duration.
      //bug workaround : play the entire video to get duration
      this.videoCamera.nativeElement.onloadeddata = _ => {
        this.getVideosDuration(this.videoCamera.nativeElement);
      }
      this.videoScreen.nativeElement.onloadeddata = _ => {
        this.getVideosDuration(this.videoScreen.nativeElement);
      }

      this.videoCamera.nativeElement.addEventListener("ended", _ => {
        if (this.screenDurationAvailable && this.cameraDurationAvailable) {
          this.videoScreen.nativeElement.pause();
        }
        this.cameraDurationAvailable = true;
        this.videoCamera.nativeElement.playbackRate = this.currentSpeed;
        this.videoCamera.nativeElement.muted = false;
      })

      this.videoScreen.nativeElement.addEventListener("ended", _ => {
        if (this.screenDurationAvailable && this.cameraDurationAvailable) {
          this.videoCamera.nativeElement.pause();
        }
        this.formatedScreenDuration = new Date(this.videoScreen.nativeElement.duration * 1000).toISOString().substr(11, 12);
        this.screenDurationAvailable = true;
        this.videoScreen.nativeElement.playbackRate = this.currentSpeed;
      })

      this.videoScreen.nativeElement.onseeked = _ => {
        if (this.cameraDurationAvailable && this.videoCamera.nativeElement.currentTime != this.videoScreen.nativeElement.currentTime + this.latency) {
          this.videoCamera.nativeElement.currentTime = this.videoScreen.nativeElement.currentTime + this.latency;
          this.sanitizeSeekIndex(this.videoCamera.nativeElement.currentTime);
          console.log("onScreenSeeked - Camera currentTime : ", this.videoCamera.nativeElement.currentTime);
          console.log("onScreenSeeked - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
        }
      }

      this.videoScreen.nativeElement.ontimeupdate = _ => {
        this.currentScreenTime = this.videoScreen.nativeElement.currentTime.toFixed(3);
        this.formatedCurrentScreenTime = new Date(this.currentScreenTime * 1000).toISOString().substr(11, 12);
      }
    });
  }

  getVideosDuration(video) {
    video.muted = true;
    video.playbackRate = 16;
    video.play(video);
  }

  sanitizeSeekIndex(index) {
    let overScreenMax = false;
    let overCameraMax = false;
    let underLatency = false;
    if (index > this.videoScreen.nativeElement.duration) {
      console.log("Seek is beyond screen duration");
      overScreenMax = true;
    }
    if (index > this.videoCamera.nativeElement.duration - this.latency) {
      console.log("Seek is beyond camera duration");
      overCameraMax = true;
    }
    if (this.latency < 0) {
      if (index < -1 * this.latency) {
        console.log("video start time is below 0");
        underLatency = true;
      }
    }

    if (overScreenMax || overCameraMax || underLatency) {
      this.videoCamera.nativeElement.pause();
      this.videoScreen.nativeElement.pause();
      if (this.latency >= 0) {
        this.videoCamera.nativeElement.currentTime = this.latency;
        this.videoScreen.nativeElement.currentTime = 0;
        console.log("videoCamera.nativeElement.currentTime changed");

      }
      if (this.latency < 0) {
        this.videoCamera.nativeElement.currentTime = 0;
        this.videoScreen.nativeElement.currentTime = 1 * -this.latency;
        console.log("videoCamera.nativeElement.currentTime changed");
      }
    }
  }

  /* Prevent video to start when player is clicked: */
  onPlayerVideoClick(event) {
    event.preventDefault();
  }

  onPreviousFrame(offset) {
    this.videoScreen.nativeElement.currentTime = this.videoScreen.nativeElement.currentTime - offset / 1000;
    console.log("onPreviousFrame - Camera currentTime: ", this.videoCamera.nativeElement.currentTime);
    console.log("onPreviousFrame - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
  }

  onNextFrame(offset) {
    this.videoScreen.nativeElement.currentTime = this.videoScreen.nativeElement.currentTime + offset / 1000;
    console.log("onNextFrame - Camera currentTime: ", this.videoCamera.nativeElement.currentTime);
    console.log("onNextFrame - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
  }

  onPlay() {
    this.videoCamera.nativeElement.play();
    this.videoScreen.nativeElement.play();
  }

  onPause() {
    this.videoCamera.nativeElement.pause();
    this.videoScreen.nativeElement.pause();
    console.log("onPause - Camera currentTime: ", this.videoCamera.nativeElement.currentTime);
    console.log("onPause - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
  }

  onRewind() {
    this.videoCamera.nativeElement.currentTime = (this.latency > 0) ? this.latency : 0;
    this.videoScreen.nativeElement.currentTime = (this.latency < 0) ? -this.latency : 0;
  }

  onStop() {
    this.onPause();
    this.onRewind();
  }

  setCurrentSpeed(speed) {
    this.currentSpeed = speed;
    this.videoScreen.nativeElement.playbackRate = speed;
    this.videoCamera.nativeElement.playbackRate = speed;
  }

  onAddMarker() {
    console.log("onAddMarker - Camera currentTime: ", this.videoCamera.nativeElement.currentTime);
    console.log("onAddMarker - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
    this.markers.push({
      timestamp: this.videoScreen.nativeElement.currentTime.toFixed(3),
    });
    this.markers$.next(this.markers);
    setTimeout(_ => {
      this.markerInputElems.last.nativeElement.focus();
    }, 100)
  }

  onMarkerNameChange() {
    this.markers$.next(this.markers);
  }

  onDeleteMarker(index) {
    this.markers.splice(index, 1);
    this.markers$.next(this.markers);
  }

  onTimestampClick(timestamp) {
    this.videoScreen.nativeElement.currentTime = timestamp;
  }

  saveMarkers() {
    this.streamingService.updateStreamingData(
      this.streamingDocumentId,
      {
        markers: this.markers
      });
  }

  applyLatency(latency) {
    this.previousLatency = this.latency; //pour le reset de latency à 0
    this.latency = latency / 1000;
    console.log("previousLatency : ", this.previousLatency);
    console.log("latency : ", this.latency);

    if (this.latency > 0) {
      this.videoCamera.nativeElement.currentTime = this.latency;
      this.videoScreen.nativeElement.currentTime = 0;
    }

    if (this.latency < 0) {
      this.videoScreen.nativeElement.currentTime = 1 * -this.latency;
      this.videoCamera.nativeElement.currentTime = 0;
    }

    //reset latency 
    if (this.latency === 0) {
      this.videoCamera.nativeElement.currentTime = this.videoScreen.nativeElement.currentTime;
    }
    console.log("applyLatency - Camera currentTime: ", this.videoCamera.nativeElement.currentTime);
    console.log("applyLatency - Screen currentTime : ", this.videoScreen.nativeElement.currentTime);
  }

  onExport() {
    console.log('this.streamingData : ', this.streamingData);
    //get skew and started_at attributes from 'videos' collection:
    let dataToExport = {};
    dataToExport["markers"] = this.markers;
    dataToExport["latency"] = this.latency;
    dataToExport["skew"] = this.streamingData['skew'];
    dataToExport["started_at_camera"] = this.streamingData['started_at_camera'];
    dataToExport["started_at_screen"] = this.streamingData['started_at_screen'];
    dataToExport["last_chunk_time_camera"] = this.streamingData['last_chunk_time_camera'];
    dataToExport["last_chunk_time_screen"] = this.streamingData['last_chunk_time_screen'];
    dataToExport["cameraFrameRate"] = this.streamingData['cameraFrameRate'];
    dataToExport["screenFrameRate"] = this.streamingData['screenFrameRate'];
    dataToExport["cameraHeight"] = this.streamingData['cameraHeight'];
    dataToExport["cameraWidth"] = this.streamingData['cameraWidth'];
    dataToExport["screenHeight"] = this.streamingData['screenHeight'];
    dataToExport["screenWidth"] = this.streamingData['screenWidth'];
    dataToExport["camera_video_duration"] = this.videoCamera.nativeElement.duration;
    dataToExport["screen_video_duration"] = this.videoScreen.nativeElement.duration;

    var blob = new Blob([JSON.stringify(dataToExport)], { type: "text/plain;charset=utf-8" });
    saveAs(blob, this.selectedParticipant + "_markers.json");
  }

  ngOnDestroy() {
    if (this.participantListSub) this.participantListSub.unsubscribe();
    if (this.recordsListSub) this.recordsListSub.unsubscribe();
    if (this.getMarkersSub) this.getMarkersSub.unsubscribe();
  }
}
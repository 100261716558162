import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LatencyService {
  lat:number=0;
  skew:any=0;
  constructor(db: AngularFireDatabase){
    let skewRef = db.object(".info/serverTimeOffset");
    skewRef.snapshotChanges().subscribe(action => {
      this.skew = action.payload.val();
    });
  }
  set(lat){
    this.lat=lat;
    console.log("latency set to : ",lat);
  }
  get(){
    return this.lat;
  }
  getSkew(){
    return this.skew;
  }
}
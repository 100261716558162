
<div mat-dialog-content>
  <h4>By clicking "Confirm" button all data from participant #{{data.userIndex+1}} will be cleared from the database.</h4>
  <p>You can export data with "Export" tab.</p>
  <p>Do you confirm you want to clear all data for participant #{{data.userIndex+1}}?</p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close class="dialogCancelButton" (click)="onCancel()">Cancel</button>
  <button mat-button class="dialogCancelConfirm" (click)="onConfirm()">Confirm</button>
</div>

<ng-container *ngIf="showForm">
  <button mat-raised-button (click)='onBackToInputsList()'> <span class="material-icons">keyboard_arrow_left</span>
    inputs list</button>
</ng-container>

<mat-card>
  <!-- TABLE LISTANT LES INPUTS : -->
  <ng-container *ngIf="!showForm">

    <div class="navigation">
      <button mat-raised-button (click)='onClickNewInput()'>+ New input</button>
    </div>

    <table mat-table [dataSource]="allInputs$ | async " class="mat-elevation-z8">
      <!-- Input Name Column -->
      <ng-container matColumnDef="inputName">
        <th mat-header-cell *matHeaderCellDef> Input Name </th>
        <td mat-cell *matCellDef="let element"> {{element.inputName}} </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions-col">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element"> <button mat-stroked-button color="primary"
            (click)="onClickView(element.inputId)">view</button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </ng-container>

  <ng-container *ngIf="showForm">
    <input-form [inputId]='inputId' [projectId]="projectId"></input-form>
  </ng-container>
</mat-card>
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';
import { StorageService } from '@services/storage/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'slider-form',
  templateUrl: './slider-form.component.html',
  styleUrls: ['./slider-form.component.css']
})
export class SliderFormComponent implements OnInit {

  @Input() inputFormData;
  @Output() inputErrors = new EventEmitter();
  errors: Array<string> = [];
  sliderImgLeftName;
  sliderImgRightName;
  sliderImgLeftFile;
  sliderImgRightFile;
  timestamp = Date.now();
  isExistingInputCode;
  isExistingInputName;

  constructor(
    private storageService: StorageService,
    private inputsService: InputsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    //Si edit :
    // if (this.inputFormData['inputId'] != undefined) {

    //   if (this.inputFormData['imgLeftDBPath'] != undefined && this.inputFormData['imgLeftDBPath'] != "") {
    //     //récupérer le nom des fichiers déjà uploadés pour les images du slider. Le nom des images est nécessaire au moment de l'appel à updateInput() dans la méthode save()
    //     this.sliderImgLeftName = this.inputFormData['imgLeftDBPath'].split("/").pop();
    //     //TODO: get projectID for getStorageFileRef
    //     // this.storageService.getStorageFileRef(this.inputFormData['imgLeftDBPath']).getDownloadURL().subscribe(downloadUrl => {
    //       // this.inputFormData['imgLeftUrl'] = downloadUrl;
    //     });
    //   }

    //   if (this.inputFormData['imgRightDBPath'] != undefined && this.inputFormData['imgLeftDBPath'] != "") {
    //     this.sliderImgRightName = this.inputFormData['imgRightDBPath'].split("/").pop();
    //     //TODO: get projectID for getStorageFileRef
    //     // this.storageService.getStorageFileRef(this.inputFormData['imgRightDBPath']).getDownloadURL().subscribe(downloadUrl => {
    //       // this.inputFormData['imgRightUrl'] = downloadUrl;
    //     });
    //   }
    // }
  }

  onChangeImgLeft(files) {
    //stocker le nom du fichier pour générer url d'accès qui sera gardé dans firestore :
    this.sliderImgLeftName = files[0]['name'];

    //garder le fichier pour l'uploader sur storage au clic sur "save" :
    this.sliderImgLeftFile = files[0];

    //modifier le path dans m'objet inputFormData :
    this.inputFormData['imgLeftDBPath'] = "/input_assets/" + this.timestamp + this.sliderImgLeftName

    //générer une url pour la preview de l'image :
    if (files.length === 0)
      this.inputFormData['imgLeftUrl'] = "";

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.inputFormData['imgLeftUrl'] = reader.result;
    }
  }

  onChangeImgRight(files) {
    //stocker le nom du fichier pour générer url d'accès qui sera gardé dans firestore :
    this.sliderImgRightName = files[0]['name'];

    //garder le fichier pour l'uploader sur storage au clic sur "save":
    this.sliderImgRightFile = files[0];

    //modifier le path dans m'objet inputFormData :
    this.inputFormData['imgRightDBPath'] = "/input_assets/" + this.timestamp + this.sliderImgRightName

    //générer une url pour la preview de l'image :
    if (files.length === 0)
      this.inputFormData['imgRightUrl'] = "";

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.inputFormData['imgRightUrl'] = reader.result;
    }
  }

  async onSave() {
    let snackMsg;
    let snackbarColor = "grey";

    this.errors = []
    this.inputErrors.emit(this.errors);

    //edit, inputId exists :
    if (this.inputFormData['inputId'] != undefined && this.inputFormData['inputName'] != undefined && this.inputFormData['inputCode'] != undefined) {
      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputCode'], 'inputCode', this.inputFormData['inputId'])
        .then(isExistingCode => {
          this.isExistingInputCode = isExistingCode;
        });

      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputName'], 'inputName', this.inputFormData['inputId'])
        .then(isExistingName => {
          this.isExistingInputName = isExistingName;
        });

      if (!this.isExistingInputCode && !this.isExistingInputName) {
        //uploader images dans storage :
        if (this.sliderImgLeftFile != undefined)
          //TODO: get projectID for uploadFile
          // this.storageService.uploadFile("/input_assets/" + this.timestamp + this.sliderImgLeftName, this.sliderImgLeftFile);
        if (this.sliderImgRightFile != undefined)
          //TODO: get projectID for uploadFile
          // this.storageService.uploadFile("/input_assets/" + this.timestamp + this.sliderImgRightName, this.sliderImgRightFile);

        //modifier document dans firestore :
        this.inputsService.updateInput(this.inputFormData['inputId'], this.inputFormData)
        snackMsg = "Input updated.";

      } else {
        this.errors['inputCode'] = this.isExistingInputCode ? "This input code already exists" : "";
        this.errors['inputName'] = this.isExistingInputName ? "This input name already exists" : "";
        this.inputErrors.emit(this.errors);
        snackMsg = "Error in input code or name.";
        snackbarColor = "mat-warn";
      }
    }
    //nouvel input
    else if (this.inputFormData['inputName'] != undefined && this.inputFormData['inputCode'] != undefined) {
      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputCode'], 'inputCode')
        .then(isExistingCode => {
          this.isExistingInputCode = isExistingCode;
        });

      await this.inputsService.isExistingAttributeVal(this.inputFormData['projectId'], this.inputFormData['inputName'], 'inputName')
        .then(isExistingName => {
          this.isExistingInputName = isExistingName;
        });

      if (!this.isExistingInputCode && !this.isExistingInputName) {
        // uploader images dans storage :
        // if (this.sliderImgLeftFile != undefined)
        //   //TODO: get projectID for uploadFile
        //   this.storageService.uploadFile("/input_assets/" + this.timestamp + this.sliderImgLeftName, this.sliderImgLeftFile);

        // if (this.sliderImgRightFile != undefined)
        //   //TODO: get projectID for uploadFile
        //   this.storageService.uploadFile("/input_assets/" + this.timestamp + this.sliderImgRightName, this.sliderImgRightFile);

        // créer document dans firestore :
        this.inputsService.addInput(this.inputFormData).then(docRef => {
          this.inputFormData['inputId'] = docRef.id;
          this.inputsService.updateInput(docRef.id, { inputId: docRef.id })
        })
        snackMsg = "Input created.";

      } else {
        this.errors['inputCode'] = this.isExistingInputCode ? "This input code already exists" : "";
        this.errors['inputName'] = this.isExistingInputName ? "This input name already exists" : "";
        this.inputErrors.emit(this.errors);
        snackMsg = "Error in input code or name.";
        snackbarColor = "mat-warn";
      }
    }
    else {
      snackMsg = "Missing required field(s).";
      snackbarColor = "mat-warn";
    }

    //Affichage du pop-up de confirmation :
    this._snackBar.open(snackMsg, '', {
      duration: 3000,
      panelClass: ['mat-toolbar', snackbarColor]
    });
  }
}


<br>
<mat-form-field appearance="fill">
  <mat-label matInput>Text indicating that test is already completed :</mat-label>
  <textarea matInput [(ngModel)]="alreadyCompleted" name="alreadyCompleted"></textarea>
</mat-form-field>
<br>

<mat-form-field appearance="fill">
  <mat-label>Text indicating the end of experimentation :</mat-label>
  <textarea matInput [(ngModel)]="experimentationEnd" name="experimentationEnd"></textarea>
</mat-form-field>
<br>

<mat-form-field appearance="fill">
  <mat-label>Text indicating that there's no stimuli available for this url :</mat-label>
  <textarea matInput [(ngModel)]="noStimuli" name="noStimuli"></textarea>
</mat-form-field>
<br>

<mat-form-field appearance="fill">
  <mat-label>Next button :</mat-label>
  <textarea matInput [(ngModel)]="nextButton" name="nextButton"></textarea>
</mat-form-field>
<br>

<mat-form-field appearance="fill">
  <mat-label>Webcam and screenshare acceptance :</mat-label>
  <textarea matInput [(ngModel)]="mediaSharing" name="mediaSharing"></textarea>
</mat-form-field>
<br>

<mat-form-field appearance="fill">
  <mat-label>Stop webcam and screenshare :</mat-label>
  <textarea matInput [(ngModel)]="mediaSharingEnd" name="mediaSharingEnd"></textarea>
</mat-form-field>
<br>

<button mat-raised-button color="primary" (click)="onSave()">Save</button>
import { Component, Input, OnInit } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';

@Component({
  selector: 'input-display-manager',
  templateUrl: './input-display-manager.component.html',
  styleUrls: ['./input-display-manager.component.css']
})
export class InputDisplayManagerComponent implements OnInit {

  @Input() inputId;
  @Input() inputIndex;
  @Input() projectId;
  inputType;

  constructor(
    private inputsServices : InputsService
  ) { }

  ngOnInit(): void {
    this.inputsServices.getInput(this.inputId).subscribe(inputVal => {
      this.inputType = inputVal["inputType"];
    })
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {


  constructor(
    private afs: AngularFirestore
  ) { }

  getStreamingData(projectId, participantId) {
    return this.afs.collection('videos', ref => ref.where('projectId', '==', projectId).where('participantId', "==", participantId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const streamingDocumentId = a.payload.doc.id;
        const streamingData = a.payload.doc.data();
        return { streamingDocumentId, streamingData };
      }))
    )
  }

  addStreamingData(data) {
    return this.afs.collection('videos').add(data)
  }

  updateStreamingData(streamingDocId, updatedData) {
    return this.afs.collection('videos').doc(streamingDocId).update(updatedData);
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SequenciesService {

  sequenciesCollection: AngularFirestoreCollection<any>;


  constructor(private afs: AngularFirestore) {
    this.sequenciesCollection = this.afs.collection<any>('sequencies');
  }

  //appelé dans le component fetch
  getSequency(userId, projectId) {
    return this.afs.collection('sequencies', ref => ref.where('userId', '==', userId.toString())).snapshotChanges().pipe(
      map(actions => actions
        .filter(a => a.payload.doc.data()['projectId'] === projectId) //ne garder que l'enregistrement de l'userId pour ce projectId
        .map(a => {
          const sequencyDocumentId = a.payload.doc.id;
          const mediaSequency = a.payload.doc.data()['sequency'].map(media => {
            return media['name']
          });
          const inputsSequency = a.payload.doc.data()['sequency'].map(media => {
            return media['inputs']
          });
          const mediaText = a.payload.doc.data()['sequency'].map(media => {
            return media['text']
          });
          return { sequencyDocumentId, mediaSequency, inputsSequency,mediaText };
        }))
    )
  }

  addSequency(userId, projectId, sequency){
    this.afs.collection('sequencies').add({
      userId: userId,
      projectId: projectId,
      sequency: sequency
    })
  }

  updateSequency(sequenciesDocumentId, updatedData) {
    return this.afs.collection('sequencies').doc(sequenciesDocumentId).update(updatedData)
  }


  // addInputsSequency(sequenciesDocumentId, updatedSequency) {
  //   console.log("updatedseq", updatedSequency)
  //   let sequency = []
  //   for (let indexMedia in updatedSequency) {
  //     sequency[indexMedia] = {
  //       inputs : updatedSequency[indexMedia]
  //     }

  //     this.afs.collection('sequencies').doc(sequenciesDocumentId).set(
  //       {sequency}, { merge: true }
  //     )
  //   }
  // }

}

import { Component, Input } from '@angular/core';
import { InputsService } from '@services/inputs/inputs.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.css']
})
export class InputsComponent {

  @Input() projectId: string;
  allInputs$: Observable<any>;
  displayedColumns: Array<string> = ["inputName", "actions-col"];
  showForm: boolean = false;
  inputId: string;

  constructor(
    private inputsService: InputsService,
  ) {}

  ngOnInit() {
    this.allInputs$ = this.setInputsList();
  }

  setInputsList() {
    return this.inputsService.getInputsByProject(this.projectId).pipe(map(inputs => {
      let inputsTab = [];
      for (let input of inputs) {
        inputsTab.push({ inputId: input.inputDocumentId, inputName: input['inputData']['inputName'] })

        //affichage par ordre alphabétique des noms d'inputs :
        inputsTab.sort(function (a, b) {
          if (a.inputName < b.inputName) { return -1; }
          if (a.inputName > b.inputName) { return 1; }
          return 0;
        })
      }
      return inputsTab
    }));
  }

  onClickNewInput() {
    this.inputId = '';
    this.showForm = true;
  }

  onClickView(inputId) {
    this.inputId = inputId;
    this.showForm = true;
  }

  onBackToInputsList() {
    this.showForm = false;
    this.allInputs$ = this.setInputsList(); //maj de la liste des inputs
  }
}

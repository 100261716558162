<!-- <div>
  <button mat-raised-button (click)='onBackToInputsList()'> <span class="material-icons">keyboard_arrow_left</span>
    inputs list</button>
</div> -->
<mat-card fxLayout="row" fxLayoutAlign="space-between">

  <form>
    <!-- SELECT INPUT TYPE -->
    <mat-form-field appearance="fill">
      <mat-label>Type of input :</mat-label>
      <mat-select [(ngModel)]="inputFormData['inputType']" name="inputType" required>
        <mat-option *ngFor="let inputType of inputTypesTab" [value]="inputType">
          {{ inputType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>

    <!-- INPUT NAME -->
    <mat-form-field appearance="fill">
      <mat-label>Input name</mat-label>
      <input matInput [(ngModel)]="inputFormData['inputName']" name="inputName" required>
    </mat-form-field>
    <p class="inputErrors">{{ inputErrors['inputName'] }}</p>
    <br>

    <!-- INPUT CODE -->
    <mat-form-field appearance="fill">
      <mat-label>Input code</mat-label>
      <input matInput [(ngModel)]="inputFormData['inputCode']" name="inputCode"
        required>
    </mat-form-field>
    <p class="inputErrors">{{ inputErrors['inputCode'] }}</p>
    <br>

    <!-- QUESTION TEXT -->
    <mat-form-field appearance="fill">
      <mat-label>Question text</mat-label>
      <textarea matInput [(ngModel)]="inputFormData['inputText']" name="inputText"></textarea>
    </mat-form-field>
    <br>

    <!-- LA QUESTION EST SUR UNE NOUVELLE PAGE : -->
    <mat-card appearance="fill">
      <mat-checkbox [(ngModel)]="inputFormData['hidePreviousInputs']" name="hidePreviousInputs" color='primary'>Hide previous inputs
      </mat-checkbox>
    </mat-card><br>

    <!-- MONTRER LA VALEUR SÉLECTIONNÉE DANS UNE BULLE : -->
    <mat-card appearance="fill">
      <mat-checkbox [(ngModel)]="inputFormData['needPreviousInputsAnswer']" name="needPreviousInputsAnswer" color='primary'>
        Appears only if previous inputs are answered
      </mat-checkbox>
    </mat-card><br>

    <!-- SLIDER -->
    <ng-container *ngIf="inputFormData['inputType'] == 'Slider'">
      <slider-form [inputFormData]="inputFormData" (inputErrors)="changeInputErrors($event)"></slider-form>
    </ng-container>

    <!-- SI YES OR NO QUESTION -->
    <ng-container *ngIf="inputFormData['inputType'] === 'Yes or no question'">
      <yes-no-form [inputFormData]="inputFormData" (inputErrors)="changeInputErrors($event)"></yes-no-form>
    </ng-container>

    <!-- SI YES OR NO QUESTION -->
    <ng-container *ngIf="inputFormData['inputType'] === 'Multiple choice question'">
      <multi-form [inputFormData]="inputFormData" (inputErrors)="changeInputErrors($event)"></multi-form>
    </ng-container>

    <!-- SI QUESTION À CHOIX MULTIPLE -->
    <!-- <ng-container *ngIf="selectedInputType === 'Multiple choice question'"> -->

    <!-- NOMBRE DE CHOIX -->
    <!-- <mat-form-field appearance="fill">
          <mat-label>Number of choices</mat-label>
          <input matInput [(ngModel)]="multipleChoiceNumber" name="multipleChoiceNumber">
        </mat-form-field>
        <br>
        <ng-container *ngIf="multipleChoiceNumber > 0">
          <ng-container *ngFor="let choice of multipleChoiceNumberArray"> -->

    <!-- AUTANT D'INPUTS QUE DE NOMBRE DE CHOIX : -->
    <!-- <mat-form-field appearance="fill">
              <mat-label>Text for choice n° {{ choice }}</mat-label>
              <input matInput [(ngModel)]="multipleChoiceTexts[choice]" name="multipleChoiceTexts[{{ choice }}]">
            </mat-form-field>
            <br>
          </ng-container>
        </ng-container>
      </ng-container>
    </div> -->

    <!-- ENREGISTRER LES INFORMATIONS DANS LA BASE DE DONNÉES : -->
    <!-- <button mat-raised-button color="primary" (click)="onSave(inputsForm)">Save</button>-->

  </form>

  <!-- PREVIEW -->
  <ng-container *ngIf="inputFormData['inputType'] !== ''">
    <div class="preview" fxFlex="grow" fxLayoutAlign="center">
      <ng-container *ngIf="inputFormData['inputType'] === 'Slider'">
        <slider [inputFormData]="inputFormData"></slider>
      </ng-container>
      <ng-container *ngIf="inputFormData['inputType'] === 'Yes or no question'">
        <yes-or-no-question [inputFormData]="inputFormData">
        </yes-or-no-question>
      </ng-container>
      <ng-container *ngIf="inputFormData['inputType'] === 'Multiple choice question'">
        <multiple-choice-question [inputFormData]="inputFormData"></multiple-choice-question>
      </ng-container>
    </div>
  </ng-container>

</mat-card>

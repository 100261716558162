import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@services/storage/storage.service';
import { MessagesService } from '@services/messages/messages.service';
import { Subscription } from 'rxjs';
import { StreamingService } from '@services/streaming/streaming.service';
import { LatencyService } from '@services/latency/latency.service';
declare var MediaRecorder: any;

@Component({
  selector: 'recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.css']
})
export class RecordingComponent implements OnInit, OnDestroy {

  id: string;
  projectId: string;
  ownerId: string;

  msgMediaSharing: string;
  msgMediaSharingEnd: string;
  recordingStarted: boolean = false;
  recordingEnded: boolean = false;

  cameraStream; //webcam stream
  screenStream; //screenshare stream
  mediaRecorderCamera; //webcam recorder
  mediaRecorderScreen; // screenshare recorder
  recordedChunksCamera: Array<any> = [];
  recordedChunksScreen: Array<any> = [];
  webcamUploadTask;
  screenUploadTask;

  isWebcamFileUploaded: boolean = false;
  isScreenFileUploaded: boolean = false;
  cameraFileUploadedPercentage: number;
  screenFileUploadedPercentage: number;

  getStreamingDataSub: Subscription
  streamingDocId: string;

  cameraAvailable: boolean = true;

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private streamingService: StreamingService,
    private latencyService: LatencyService
  ) {
    window.onbeforeunload = function (e) {
      var e = e || window.event;
      if (e) {
        e.returnValue = 'La fermeture de la page mettra fin au test...';
      }
    };

    this.route.queryParams.subscribe(async params => {
      this.id = params['id'];
      this.projectId = params['project'];
      this.ownerId = params['owner'];
    });

    this.getStreamingDataSub = this.streamingService.getStreamingData(this.projectId, this.id).subscribe(data => {
      if (data[0] != undefined) {
        this.streamingDocId = data[0]["streamingDocumentId"];
      } else {
        this.streamingService.addStreamingData({
          projectId: this.projectId,
          participantId: this.id
        })
      }

      if (this.streamingDocId != undefined) {
        let skew = this.latencyService.getSkew();
        console.log("skew : ", skew);
        this.streamingService.updateStreamingData(this.streamingDocId, { skew: skew });
      }
    })

  }

  ngOnInit() {
    /* définir le dossier storage où aller chercher les vidéos : */
    // this.storageService.setRepository(this.projectId)

    //récupérer les messages à afficher :
    this.messagesService.getMessages(this.projectId).subscribe(messages => {
      if (messages.length != 0) {
        this.msgMediaSharing = messages[0]['messagesData']['mediaSharing'];
        this.msgMediaSharingEnd = messages[0]['messagesData']['mediaSharingEnd'];
      }
    })
  }

  async onAcceptMediaSharing() {
    // console.log("supported constraints : ", navigator.mediaDevices.getSupportedConstraints());
    //video et audio 
    try {
      this.cameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      this.streamingService.updateStreamingData(this.streamingDocId, {
        cameraFrameRate: this.cameraStream.getVideoTracks()[0].getSettings().frameRate,
        cameraHeight: this.cameraStream.getVideoTracks()[0].getSettings().height,
        cameraWidth: this.cameraStream.getVideoTracks()[0].getSettings().width
      });
      //manage streaming interruption:
      this.cameraStream.getVideoTracks()[0].addEventListener('ended', () => {
        this.onStopRecording();
      });
      this.cameraStream.getAudioTracks()[0].addEventListener('ended', () => {
        this.onStopRecording();
      });
    } catch (error) {
      console.log("no camera detected")
      this.cameraAvailable = false;
    }

    if (this.cameraAvailable) {
      // @ts-ignore
      this.screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      this.streamingService.updateStreamingData(this.streamingDocId, {
        screenFrameRate: this.screenStream.getVideoTracks()[0].getSettings().frameRate,
        screenHeight: this.screenStream.getVideoTracks()[0].getSettings().height,
        screenWidth: this.screenStream.getVideoTracks()[0].getSettings().width
      });
      //manage streaming interruption or stop with "Stop sharing" chrome button:
      this.screenStream.getVideoTracks()[0].addEventListener('ended', () => {
        this.onStopRecording();
      });

      //enregsitrement du stream remote webcam et du screenshare dans un fichier côté client via des MediaRecorder:
      this.mediaRecorderCamera = new MediaRecorder(this.cameraStream, { mimeType: 'video/webm; codecs=avc1,opus' });
      this.mediaRecorderScreen = new MediaRecorder(this.screenStream, { mimeType: 'video/webm; codecs=avc1,opus' });

      this.startRecording();
    }
  }

  startRecording() {
    // let slicetime = 50;
    let started_at_camera = new Date();
    this.streamingService.updateStreamingData(this.streamingDocId, { started_at_camera: started_at_camera })
    this.mediaRecorderCamera.start(/* slicetime */);

    let started_at_screen = new Date();
    this.streamingService.updateStreamingData(this.streamingDocId, { started_at_screen: started_at_screen });
    this.mediaRecorderScreen.start(/* slicetime */);

    let last_chunk_time_camera;
    // let first_chunk_time_camera;
    
    //store streaming data in a array when they arrive:
    this.mediaRecorderCamera.addEventListener("dataavailable", async (event) => {
      if (event.data && event.data.size > 0) {
        last_chunk_time_camera = new Date();
        this.streamingService.updateStreamingData(this.streamingDocId, { last_chunk_time_camera: last_chunk_time_camera });
        console.log("last chunk camera : ", last_chunk_time_camera)
        this.recordedChunksCamera.push(event.data);
      }
    });
    let last_chunk_time_screen;
    this.mediaRecorderScreen.addEventListener("dataavailable", async (event) => {
      if (event.data && event.data.size > 0) {
        last_chunk_time_screen = new Date();
        this.streamingService.updateStreamingData(this.streamingDocId, { last_chunk_time_screen: last_chunk_time_screen });
        console.log("last chunk screem : ", last_chunk_time_screen)
        this.recordedChunksScreen.push(event.data);
      }
    })

    //upload streaming files when mediaRecorders are stopped:
    let participant = (Number(this.id) < 9) ? "P0" + this.id : "P" + this.id;
    this.mediaRecorderCamera.addEventListener("stop", _ => {
      var blob = new Blob(this.recordedChunksCamera, { type: 'video/webm' });
      console.log("blob size : ", blob.size);
      this.webcamUploadTask = this.storageService.uploadFile(this.projectId, "/records/" + participant + "/" + participant + "_camera.webm", blob, this.ownerId);
      this.webcamUploadTask.percentageChanges().subscribe(percentage => {
        this.cameraFileUploadedPercentage = percentage;
        if (percentage === 100) {
          this.isWebcamFileUploaded = true;
        }
      })
    });
    this.mediaRecorderScreen.addEventListener("stop", _ => {
      var blob = new Blob(this.recordedChunksScreen, { type: 'video/webm' });
      this.screenUploadTask = this.storageService.uploadFile(this.projectId, "/records/" + participant + "/" + participant + "_screen.webm", blob, this.ownerId);
      this.screenUploadTask.percentageChanges().subscribe(percentage => {
        this.screenFileUploadedPercentage = percentage;
        if (percentage === 100) {
          this.isScreenFileUploaded = true;
        }
      })
    });

    this.recordingStarted = true;
  }

  onStopRecording() {
    //arrêter l'enregistrement :
    if (this.mediaRecorderCamera) {
      this.mediaRecorderCamera.stop();
    }
    if (this.mediaRecorderScreen) {
      this.mediaRecorderScreen.stop();
    }

    //arrêter le stream :
    if (this.cameraStream) {
      this.cameraStream.getTracks().forEach(track => track.stop());
      this.cameraStream = null;
    }
    if (this.screenStream) {
      this.screenStream.getTracks().forEach(track => track.stop());
      this.screenStream = null;
    }

    this.recordingEnded = true;
  }

  ngOnDestroy() {
    if (this.getStreamingDataSub) this.getStreamingDataSub.unsubscribe();
  }
}

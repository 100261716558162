import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {


  constructor(
    private afs: AngularFirestore
  ) { }

  getMessages(projectId) {
    return this.afs.collection('messages', ref => ref.where('projectId', '==', projectId)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const messagesDocumentId = a.payload.doc.id;
        const messagesData = a.payload.doc.data();
        return { messagesDocumentId, messagesData };
      }))
    )
  }

  addMessages(data) {
    return this.afs.collection('messages').add(data)
  }

  updateMessages(messagesDocId, updatedData) {
    return this.afs.collection('messages').doc(messagesDocId).update(updatedData);
  }
}

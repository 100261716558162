import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FeedbackService } from '@services/feedback/feedback.service';
import { InputsService } from '@services/inputs/inputs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit, OnDestroy {
  @Input() projectId;

  participants: any[] = [];
  selectedParticipant: number;
  projectInputsCodes = [];
  getFeedbacksSub: Subscription;
  getInputsSub: Subscription;

  constructor(
    private feedbackService: FeedbackService,
    private inputsService: InputsService
  ) {
    this.getFeedbacksSub = this.feedbackService.getObs().subscribe(data => {
      console.log("[Subscription]: Constructor Export get feedbacks")
      data.map(element => {
        // console.log("element :", element.proje)
        if (element.project_id === this.projectId && !this.participants.includes(element.id)) {
          this.participants.push(element.id);
          this.participants = this.participants.sort(function (a, b) { return a - b; });
        }
      });
    });
  }

  ngOnInit(): void {
    this.getInputsSub = this.inputsService.getInputByProject(this.projectId).subscribe(inputsData => {
      console.log("[Subscription]: Get Input By Project ID")
      for (let inputData of inputsData) {
        this.projectInputsCodes.push(inputData['inputCode'])
      }
    })
  }

  /* Tout exporter : */
  onExport() {
    let feedbacks = this.feedbackService.get();
    let feedbacks_json = JSON.stringify(feedbacks);
    var blob = new Blob([feedbacks_json], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "feedback_export.json");
  }

  /* Export par participant : */
  onExportParticipant() {
    let feedbacks = this.feedbackService.get();
    let participantFeedbacks = feedbacks.map(element => {
      if (element.id === this.selectedParticipant && element.project_id == this.projectId) {
        return element;
      }
    });

    participantFeedbacks = participantFeedbacks.filter(function (el) {
      return el != null;
    });

    // let reducer = (accumulator, currentValue) => {
    //   if(!accumulator[currentValue.video_id]) {
    //     accumulator[currentValue.video_id] = currentValue
    //   } else {
    //     for(let feedback in accumulator) {
    //       if(accumulator[feedback].video_id == currentValue.video_id && accumulator[feedback].createdAt < currentValue.createdAt) {
    //         accumulator[feedback] = currentValue;
    //       }
    //     }
    //   }
    //   return accumulator;
    // };

    // let reducedParticipantFeedbacks = Object.values(participantFeedbacks.reduce(reducer, {}));

    /* Extraction de tous les résultats pour un participant : */
    // let feedbacks_json_all = JSON.stringify(participantFeedbacks);
    // var blob_all = new Blob([feedbacks_json_all], { type: "text/plain;charset=utf-8" });
    // saveAs(blob_all, "feedback_P" + this.selectedParticipant + "_all.json");

    /* extraction des derniers résultat sur par vidéo pour un participant : */
    let feedbacks_json = JSON.stringify(participantFeedbacks);
    var blob = new Blob([feedbacks_json], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "feedback_Project-" + this.projectId + "_Part-" + this.selectedParticipant + ".json");
  }

  onExportProject() {
    let feedbacksByProject = [];

    //récupérer tous les feedbacks du projet :
    this.feedbackService.get().map(element => {
      if (element.project_id == this.projectId) {
        feedbacksByProject.push(element);
      }
    });

    //trier par participant id, puis video_id:
    feedbacksByProject.sort((a, b) => {
      a.id = Number(a.id);
      b.id = Number(b.id);
      a.video_id = Number(a.video_id);
      a.video_id = Number(a.video_id);

      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }

      if (a.id == b.id) {
        if (a.video_id < b.video_id) {
          return -1;
        }
        if (a.video_id > b.video_id) {
          return 1;
        }
      }
    })

    //initialisation d'un tableau recueillant uniquement les données correspondantes aux : participantId, nom de vidéo et valeurs choisis pour les inputs
    let feedbacksTab = [["id", "video_name"]];

    for (let i = 0; i < feedbacksByProject.length; i++) {

      //n'ajouter que les résultats d'inputs au header de feedbacksTab (feedbacksTab[0]):
      for (let property in feedbacksByProject[i]) {
        if (this.projectInputsCodes.includes(property) && !feedbacksTab[0].includes(property)) {
          feedbacksTab[0].push(property);
        }
      }

      //puis ajouter une ligne au tableau feedbacksTab par feddback, avec uniquement les item du header (feedbacksTab[0]) :
      let row = [];
      for (let item of feedbacksTab[0]) {
        row.push(feedbacksByProject[i][item]);
      }
      feedbacksTab.push(row);
    }

    //formater feedbacksTab en txt :
    let feedbackTabToString = '';
    for (let feedback of feedbacksTab) {
      for (let feedbackItem of feedback) {
        if (feedbackItem == undefined) feedbackItem = "";
        feedbackTabToString += feedbackItem + "\t";
      }
      feedbackTabToString += "\r\n";
    }

    var blob = new Blob([feedbackTabToString], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "feedback_Project-" + this.projectId + ".txt");
  }

  onClear() {
    this.feedbackService.clearAllFeedbacks();
  }

  ngOnDestroy(){
    console.log('[On destroy] export.component.ts');
    if(this.getFeedbacksSub) this.getFeedbacksSub.unsubscribe();
    if(this.getInputsSub) this.getInputsSub.unsubscribe();
  }

}

import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders
} from '@angular/common/http';
import { LatencyService } from '@services/latency/latency.service';

@Injectable()
export class HttpTimeInterceptor implements HttpInterceptor {
  constructor(public latencyService:LatencyService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    const httpRequest = req.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    });
    
    // extend server response observable with logging
    return next.handle(httpRequest)
      .pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          event => {
            return "valid"
          },
          // Operation failed; error is an HttpErrorResponse
          error => {
            const elapsed = Date.now() - started;
            // const msg = `${req.method} "${req.urlWithParams}"
            //    ${ok} in ${elapsed} ms.`;
            // console.log("latency", elapsed);
            this.latencyService.set(elapsed);
            // console.log("Arrived");
            return "invalid";
          }
        )
      );
  }
}

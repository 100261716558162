<ng-container *ngIf="inputFormData">
  <div class="yesOrNo" >
    <span>{{ inputFormData['inputText'] }}</span>
    <br>
    <mat-radio-group (change)="onFinish()"
      [(ngModel)]="inputFormData['value']">
      <mat-radio-button value="yes">
        {{ inputFormData['inputYesText'] }}
      </mat-radio-button>
      <mat-radio-button value="no">
        {{ inputFormData['inputNoText'] }}
      </mat-radio-button>
    </mat-radio-group>
    <!-- <input id="yes" name="yesOrNoQuestion" type="radio" value="yes" [(ngModel)]="inputFormData['value']">
    <label for="yes"> {{ inputFormData['inputYesText'] }} </label>
    <input id="no" name="yesOrNoQuestion" type="radio" value="no" [(ngModel)]="inputFormData['value']">
    <label for="no"> {{ inputFormData['inputNoText'] }} </label> -->
  </div>
</ng-container>
